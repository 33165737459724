<template>
  <div class="contact">
    <navTop :index="3"></navTop>
    <div class="map">
      <div id="bai-du-map"></div>
    </div>
    <div class="way">
      <div class="title">商务合作联系方式</div>
      <div class="titles">深圳爱贝源科技有限公司</div>
      <div class="content">
        <div class="content_left">
          <div class="tel_1">
            <div class="title">
              <img
                src="https://cdns.iamberry.com/metapure/static/pcImg/contact-icon1.png"
                alt=""
              />
              商务合作联系方式
            </div>
            <p>地址：深圳市龙华区龙华街道景龙社区龙华大道3639号环智中心C座15层1505、1506B</p>
            <p>邮编：518000</p>
            <p>邮箱：aiberle@iamberry.com</p>
            <p>电话：189 2529 2910</p>
          </div>
          <div class="tel_2">
            <div class="title">
              <img
                src="https://cdns.iamberry.com/metapure/static/pcImg/contact-icon2.png"
                alt=""
              />
              客户服务联系方式
            </div>
            <p>电话：400 800 6692</p>
          </div>
        </div>
        <div class="content_right">
          <div class="tel_3">
            <div class="title">
              <img
                src="https://cdns.iamberry.com/metapure/static/pcImg/contact-icon3.png"
                alt=""
              />
              在线客服联系方式
            </div>
            <img
              src="https://cdns.iamberry.com/metapure/static/pcImg/contact-us-code.png"
              alt=""
            />
            <p>爱贝源公众号 - 在线客服</p>
          </div>
        </div>
      </div>
    </div>
    <navbt></navbt>
  </div>
</template>
<script>
import navTop from "@/components/NavTop";
import navbt from "@/components/navBt";
import AMapLoader from "@amap/amap-jsapi-loader";
window._AMapSecurityConfig = {
  securityJsCode: "516195c91c09ba390aab8f359fe04b91",
};
export default {
  name: "Contact",
  components: {
    navTop,
    navbt,
  },
  data() {
    return {
      map: null,
      mouseTool: null,
      overlays: [],
      auto: null,
      placeSearch: null,
    };
  },
  mounted(){
    this.initMap()
  },
  methods: {
    initMap() {
      AMapLoader.load({
        key: "0391591b9783173f28331141fa094115",
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      })
        .then((AMap) => {
          // 初始化地图
          this.map = new AMap.Map("bai-du-map", {
            viewMode: "2D", //  是否为3D地图模式
            zoom: 20, // 初始化地图级别
            center: [114.030343,22.643627], //中心点坐标  郑州
            resizeEnable: true,
          });
          this.setMapMarker();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // 增加点标记
    setMapMarker () {
      // 创建 AMap.Icon 实例
      let icon = new AMap.Icon({
        size: new AMap.Size(46, 46), // 图标尺寸
        image: 'https://cdns.iamberry.com/metapure/static/images/postion2.png', // Icon的图像
        imageSize: new AMap.Size(46, 46), // 根据所设置的大小拉伸或压缩图片
        imageOffset: new AMap.Pixel(0, 0)  // 图像相对展示区域的偏移量，适于雪碧图等
      });
      let makerList = []
      let marker = new AMap.Marker({
          map: this.map,
          zIndex: 9999999,
          icon: icon, // 添加 Icon 实例
          offset: new AMap.Pixel(-13, -30), //icon中心点的偏移量
          position: [114.030343,22.643627] // 经纬度对象new AMap.LngLat(x, y)，也可以是经纬度构成的一维数组[116.39, 39.9]
      });
      makerList.push(marker)
      this.map.add(makerList)
      // 自动适应显示想显示的范围区域
      this.map.setFitView();
    }
  }
};
</script>
<style lang="less" scoped>
#bai-du-map {
    overflow: hidden;
    width: 100%;
    height: 100%;
    margin: 0;
    font-family: "微软雅黑";
}
/* 隐藏高德logo  */
.amap-logo {
    display: none !important;
}
/* 隐藏高德版权  */
.amap-copyright {
    display: none !important;
}
.contact {
  width: 100%;
  min-width: 1440px;
  margin: 0 auto;
}

.way {
  width: 100%;
  // width: 1440px;
  height: 815px;
  box-sizing: border-box;
  background: url(https://cdns.iamberry.com/metapure/static/pcImg/contact-us-bg.png) no-repeat 100%/cover;
}

.way > .title {
  font-size: 36px;
  font-weight: 700;
  color: #242933;
  line-height: 56px;
  padding-top: 120px;
  text-align: center;
}

.way > .titles {
  font-size: 22px;
  font-weight: 500;
  color: #5b5f68;
  line-height: 29px;
  margin-top: 20px;
  text-align: center;
}

.way > .content {
  display: flex;
  width: 1080px;
  height: 408px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0px 10px 26px 0px rgba(0, 0, 0, 0.02);
  border-radius: 24px;
  backdrop-filter: blur(50px);
  margin: 0 auto;
  margin-top: 20px;
  padding: 68px 140px;
}

.way > .content .title img {
  width: 28px;
  height: 28px;
  margin-right: 19px;
}

.way > .content .title {
  display: flex;
  font-size: 18px;
  font-weight: 700;
  color: #242933;
  line-height: 29px;
}

.way > .content p {
  font-size: 16px;
  font-weight: normal;
  color: #5b5f68;
  line-height: 21px;
  margin: 16px 0 0 47px;
}

.content .tel_2 {
  margin-top: 41px;
}

.content .tel_3 {
  margin-left: 178px;
}

.content .tel_3 > img {
  width: 206px;
  height: 206px;
  margin: 13px 0 0 34px;
}

.content .tel_3 > p {
  margin-top: 2px;
}

.map {
  width: 100%;
  height: 800px;
  box-sizing: border-box;
  // background-color: #000;
}
</style>