<template>
  <div class="introduce">
    <navTop/>
    <div class="topNav">
        <a :href="'#'+item.id" :class="{ 'chose': toIndex === index }" @click="msToIndex(index, `#${item.id}`)" v-for="(item, index) in maishu" :key="index">{{ item.name }}</a>
    </div>
    <div class="banner">
        <swiper ref="mySwiper" class="swiper" :options="options">
          <swiper-slide class="swiperct" v-for="(item,index) in prodatas.banner" :key="index">
            <img :src="splitImageHttp(item)" alt="" />
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>

    </div>  
    <div class="productName">
        <p class="name1">{{ prodatas.name }}</p>
        <p class="name2">{{ prodatas.note }}</p>
    </div>
    <div class="detail">
        <img id="jieshao" :src="splitImageHttp(prodatas.jieshao)" alt="">
        <img id="texing" :src="splitImageHttp(prodatas.texing)" alt="">
        <img id="canshu" :src="splitImageHttp(prodatas.canshu)" alt="">
        <!-- <img id="detail" :src="splitImageHttp(prodatas.detail)" alt=""> -->
    </div>
    <img class="shopping"  :src="splitImageHttp('metapure/static/mobile/shopping.png')" alt="" @click="goshop(prodatas.purchaseLink)">
    <navBt/>
  </div>
</template>
<script>
import navTop from "@/components/mobile/mobileTop.vue";
import navBt from "@/components/mobile/mobileBt.vue";
import jsonData from "@/commom/mock.js";
export default {
  components: {
    navTop,
    navBt,
  },
  created(){
    let id = this.$route.query.id || '1'
    let data = jsonData.productListDetail.filter(item=>item.id===id)
    this.prodatas = data[0]
    
  },
  watch: {
    '$route.query.id'(old,now){
      this.$router.go(0)
    }
  },
  data() {
    return {
      toIndex: 0,
      maishu: [
        { name: "介绍", id: "jieshao" },
        { name: "特性", id: "texing" },
        { name: "参数", id: "canshu" },
        // { name: "产品支持", id: "#detail" },
      ],
      prodatas: null,
      options: {
        // loop: true,
        autoplay: true,
        // autoplay: {
        //   //swiper手动滑动之后自动轮播失效的解决方法,包括触碰，拖动，点击pagination,重新启动自动播放
        //   disableOnInteraction: false,
        //   // 自动播放时间：毫秒
        //   delay: 4000,
        // },
        // freeMode: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        //   bulletActiveClass: 'my-bullet-active',
        },
      },
    };
  },
  methods: {
    msToIndex(key, selector){
        this.toIndex = key
        let _this = this
        this.$nextTick(()=>{
          setTimeout(()=>{
            let company = _this.$el.querySelector(selector)
            document.documentElement.scrollTop = company.offsetTop //苹果滚动
            document.body.scrollTop = company.offsetTop //安卓滚动
          },100)
        })
    },
    //跳转购买链接
    goshop(url){
      let u = navigator.userAgent
      let isWeixin = u.toLowerCase().indexOf('micromessenger') !== -1 // 微信内
      let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1 //android终端
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      // 微信内
      if(isWeixin) {
        // alert('请复制链接在其他浏览器上打开')
        // 微信暂定打开网页版
        window.location.href = url
      } else {
        //android端
        if (isAndroid) {
          //安卓app的scheme协议
          window.location.href = url.replace('https://', 'taobao://')
          setTimeout(function(){
            let hidden = window.document.hidden || window.document.mozHidden || window.document.msHidden ||window.document.webkitHidden 
            if(typeof hidden =="undefined" || hidden ==false){
              //应用宝下载地址 (emmm 找不到淘宝应用宝的地址，这里放的是 lucky coffee 地址)
              window.location.href ="https://a.app.qq.com/o/simple.jsp?pkgname=com.taobao.taobao";
            }
          }, 2000);
        }
        //ios端
        if (isIOS) {
          //ios的scheme协议
          window.location.href = url.replace('https://', 'taobao://')
          setTimeout(function(){
            let hidden = window.document.hidden || window.document.mozHidden || window.document.msHidden ||window.document.webkitHidden 
            if(typeof hidden =="undefined" || hidden ==false){
              //App store下载地址
              window.location.href = "http://itunes.apple.com/app/id387682726";
            }
          }, 2000);
        }
      }
    }
  }
};
</script>
<style lang="less" scoped>
/deep/ .swiper-pagination-bullet{
  width: 12rem !important;
  height: 12rem !important;
  margin: 0 6rem !important
}
.introduce {
  width: 100%;
  .productName{
    width: 100%;
    min-height: 100rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 90rem;
    .name1{
        color: #242933;
        font-size: 32rem;
        line-height: 50rem;
        font-weight: 700;
        margin-top: 40rem;
        margin-bottom: 20rem;
    }
    .name2{
        color: #5B5F68;
        font-size: 29rem;
        line-height: 37rem;
    }
  }
  .detail{
    width: 100%;
    box-sizing: border-box;
    padding: 0 32rem 90rem 32rem;
    img{
        width: 686rem;
    }
  }
  .banner{
    width: 100%;
    .swiper{
        width: 750rem;
        height: 750rem;
        /deep/ .swiper-pagination-bullet-active{
            background: #ffffff;
        }
        .swiperct{
            width: 100%;
            height: 100%;
            img{
                width: 100%;
                height: 100%;
            }
        }
    }
  }
  .topNav{
    width: 100%;
    height: 96rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 97rem;
    box-sizing: border-box;
    .chose{
        color: #242933;
        font-weight: 700;
    }
    a{
      color: #717171;
      font-size: 28rem;
      text-decoration: none
    }
  }
}
.shopping{
  position: fixed; 
  bottom: 300rem;
  right: 32rem;
  width: 112rem;
  height: 112rem;
  z-index: 10;
}
</style>