import Vue from 'vue'
import App from './App'
import router from './router/index'
import store from './store/index';
import 'element-ui/lib/theme-chalk/index.css';
import { Button, CarouselItem, Carousel, Dialog } from 'element-ui';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import './router/permission.js'
import 'swiper/dist/css/swiper.css'
import 'lib-flexible/flexible.js'
Vue.prototype.$eventBus = new Vue()
Vue.use(VueAwesomeSwiper)
Vue.prototype.splitImageHttp = (image => {
  return 'https://cdns.iamberry.com/' + image
})
Vue.use(Button);
Vue.use(CarouselItem);
Vue.use(Carousel);
Vue.use(Dialog);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    // 实例创建完成后被立即调用rem换算方法,解决内容闪现问题
    // if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
    //   //移动端使用
    //   setRem();
    //   window.addEventListener('resize', setRem);//浏览器窗口大小改变时调用rem换算方法
    // }
  }
  // 手机端

}).$mount('#app')
