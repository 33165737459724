<template>
  <div class="introduce">
    <navTop :index="1"></navTop>
    <div class="banner">
      <div class="nav">
        <div class="nav_left">
          <span @click="goHome">首页</span>
          <img class="right1" :src="right" alt="" />
          <span @click="goCenter">产品中心</span>
          <img class="right2" :src="right" alt="" />
          <span @click="goXilie">{{ xilie }}</span>
          <img class="right2" :src="right" alt="" />
          <span style="color: #242933; font-weight: 700; cursor:auto">{{ name }}</span>
        </div>
        <div class="nav_right">
          <a :href="'#' + item.id" :class="{ chose: toIndex === index }" v-for="(item, index) in maishu" :key="index"
            @click="choseToindex(index)">
            {{ item.name }}
          </a>
        </div>
      </div>
      <div class="banner_pro">
        <swiper ref="mySwiper" class="swiper" :options="options">
          <swiper-slide class="swiperct" v-for="(item, index) in prodatas.banner" :key="index">
            <img :src="splitImageHttp(item)" alt="" />
          </swiper-slide>
        </swiper>
      </div>
      <div class="banner_change">
        <img class="lr btn-left rotate" :src="lrchose" alt="">
        <div class="proImg">
          <img :class="{ border: imgIndex === index }" :src="splitImageHttp(item)" alt=""
            v-for="(item, index) in prodatas.banner" :key="index" @click="choseImg(index)">
        </div>
        <img class="lr btn-right" :src="lrchose" alt="">
      </div>
      <p class="pro_name">{{ prodatas.name }}</p>
      <span class="pro_detail">{{ prodatas.note }}</span>
      <img style="position: fixed;right:32px;bottom:370px;cursor:pointer"
        :src="splitImageHttp('metapure/static/pcImg/goumai.png')" width="67px" height="174px"
        @click="goShowping(prodatas.purchaseLink)" alt="">
    </div>
    <div class="pro_detailImg">
      <img id="jieshao" :src="splitImageHttp(prodatas.jieshao)" alt="">
      <img id="texing" :src="splitImageHttp(prodatas.texing)" alt="">
      <img id="canshu" :src="splitImageHttp(prodatas.canshu)" alt="">
      <!-- <img id="detail" :src="splitImageHttp(prodatas.detail)" alt=""> -->
      <div class="pro_nav">
        <div class="pro_navLeft" @click="prev(prodatas.curIndex === 0)">
          <img :class="{ rotate: prodatas.curIndex !== 0 }" :src="prodatas.curIndex !== 0 ? lrchose : lr" alt="">
          <span :class="{ roatecolor: prodatas.curIndex !== 0 }">前一个：</span>
          <span :class="{ roatecolor: prodatas.curIndex !== 0 }">{{ prodatas.prevName }}</span>
        </div>
        <div class="pro_navRight" @click="next(prodatas.curIndex === prodatas.maxleng)">
          <span :class="{ roatecolor: prodatas.curIndex !== prodatas.maxleng }">后一个：</span>
          <span :class="{ roatecolor: prodatas.curIndex !== prodatas.maxleng }">{{ prodatas.nextName }}</span>
          <img :class="{ rotate: prodatas.curIndex === prodatas.maxleng }"
            :src="prodatas.curIndex !== prodatas.maxleng ? lrchose : lr" alt="">
        </div>
      </div>
    </div>
    <navbt></navbt>
  </div>
</template>
<script>
import navTop from "@/components/NavTop";
import right from "@/assets/right.png";
import lr from "@/assets/lr.png";
import lrchose from "@/assets/lrchose.png";
// import goumai from "@/assets/goumai.png";
import navbt from "@/components/navBt";
import jsonData from "@/commom/mock.js";
const c_swiper = document.getElementsByClassName("swiper")
export default {
  components: {
    navTop,
    navbt,
  },
  data() {
    return {
      maishu: [
        { name: "介绍", id: 'jieshao' },
        { name: "特性", id: 'texing' },
        { name: "参数", id: 'canshu' },
        // { name: "产品支持", id: '#detail' },
      ],
      lrchose: lrchose,
      lr: lr,
      right: right,
      toIndex: 0,
      imgIndex: 0,
      name: '',
      prodatas: {
        name: "H矿化系列岩泉 Healthy 7 ",
        note: "富锶天然矿化 | RO多重过滤 | 即热即饮",
        banner: [],
        maxleng: 1,
        curIndex: 0,
        nextName: '岩泉Healthy 7',
        prevName: '岩泉Healthy'
      },
      xilie: '',
      //配置项例子
      options: {
        loop: true,
        autoplay: true,
        // autoplay: {
        //   //swiper手动滑动之后自动轮播失效的解决方法,包括触碰，拖动，点击pagination,重新启动自动播放
        //   disableOnInteraction: false,
        //   // 自动播放时间：毫秒
        //   delay: 4000,
        // },
        on: {
          // 无限循环，将activeIndex换为realIndex
          slideChange: () => { this.imgIndex = c_swiper[0].swiper.realIndex }

        },
        navigation: {
          //下一张
          nextEl: '.btn-right',//下一张标签类名可以自定义
          //上一张
          prevEl: '.btn-left'//上一张标签类名可以自定义
        }
        // freeMode: true,
        // pagination: {
        //   el: ".swiper-pagination",
        //   clickable: true,
        //   // bulletClass: "my-bullet",
        // },
      },
    };
  },
  created() {
    let id = this.$route.query.id || '1'
    let data = jsonData.productListDetail.filter(item => item.id === id)
    let xilie = jsonData.productxilie
    this.prodatas = data[0]
    this.prodatas.maxleng = xilie.length - 1
    this.prodatas.nextName = xilie[this.prodatas.curIndex + 1] ? xilie[this.prodatas.curIndex + 1].name : '无'
    this.prodatas.prevName = xilie[this.prodatas.curIndex - 1] ? xilie[this.prodatas.curIndex - 1].name : '无'
    this.xilie = this.$route.query.series
    this.name = this.$route.query.name
  },
  methods: {
    choseImg(index) {
      // 无限循环将slideTo替换为slideToLoop
      this.$refs.mySwiper.swiper.slideToLoop(index, 0, false);
    },
    choseToindex(key) {
      this.toIndex = key
    },
    goShowping(url) {
      window.open(url)
    },
    prev(ists) {
      if (ists) { // 没有上一个
        return
      }
      this.$router.push({ path: '/home/product', query: { index: this.prodatas.curIndex - 1 } })
    },
    next(ists) {
      if (ists) { // 最后一个了
        return
      }
      this.$router.push({ path: '/home/product', query: { index: this.prodatas.curIndex + 1 } })
    },
    goHome() {
      this.$router.push({
        path: '/home'
      })
    },
    goCenter() {
      this.$router.push({
        path: '/home/product'
      })
    },
    goXilie() {
      this.$router.push({
        path: '/home/product', query: { index: this.prodatas.curIndex }
      })
    },
  }
};
</script>
<style lang="less" scoped>
.introduce {
  width: 100%;
  min-width: 1440px;
  min-height: 100vh;
  margin: 0 auto;

  .pro_detailImg {
    width: 100%;
    min-height: 200px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 120px;

    .pro_nav {
      margin-top: 101px;
      width: 932px;
      min-height: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #999999;
      cursor: pointer;

      .pro_navLeft {
        display: flex;
        align-items: center;

        img {
          width: 20px;
          height: 20px;
          margin-right: 14px;
        }
      }

      .pro_navRight {
        display: flex;
        align-items: center;

        img {
          width: 20px;
          height: 20px;
          margin-left: 14px;
        }
      }

      .roatecolor {
        color: #242933 !important;
      }

      .rotate {
        transform: rotate(180deg);
      }
    }

    img {
      width: 900px;
    }
  }

  .banner {
    width: 100%;
    min-height: 500px;
    box-sizing: border-box;
    padding-left: 180px;
    padding-right: 180px;
    padding-top: 30px;
    padding-bottom: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .pro_name {
    font-size: 24px;
    line-height: 38px;
    color: #242933;
    margin-top: 32px;
    margin-bottom: 7px;
  }

  .pro_detail {
    font-size: 18px;
    line-height: 24px;
    color: #5B5F68;
  }

  .banner_change {
    width: 932px;
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .proImg {
      display: flex;
      align-items: center;

      .border {
        border: 1px solid #05B3E7;
      }

      img {
        width: 80px;
        height: 80px;
        margin-right: 10px;
        cursor: pointer;
      }

      img :last-child {
        margin-right: 0px !important;
      }
    }

    .rotate {
      transform: rotate(180deg);
    }

    .lr {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  .banner_pro {
    width: 1080px;
    min-height: 546px;
    padding-top: 29px;

    .swiper {
      width: 515px;
      height: 546px;

      .swiperct {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 500px;
          height: 500px;
        }
      }
    }
  }

  .nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1080px;
    min-height: 20px;


    .nav_left {
      display: flex;
      align-items: center;
      color: #717171;
      font-size: 16px;

      span {
        cursor: pointer;
      }

      .right1 {
        margin-left: 5px;
        margin-right: 7px;
      }

      .right2 {
        margin-left: 10px;
        margin-right: 10px;
      }
    }

    .nav_right {
      width: 176px;
      min-height: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      //   margin-left: 388px;
      a {
        font-size: 16px;
        line-height: 21px;
        color: #717171;
      }

      .chose {
        color: #242933 !important;
        line-height: 25px;
        font-weight: 700;
      }
    }
  }
}
</style>