<template>
  <div class="mAbout">
    <navTop></navTop>
    <div class="culture">
      <div class="title">关于<br />Aiberle爱贝源</div>
      <div class="line"></div>
      <div class="text">以创新智造为驱，以关爱呵护为本<br />守护家庭用水健康，创造美好生活。</div>
      <div class="content">
        <div>
          <div class="title1">企业使命</div>
          <div class="text1">守护生命之源，呵护家庭用水健康。</div>
        </div>
        <div>
          <div class="title1">核心价值观</div>
          <div class="text1">关爱、用户导向、创新设计、责任感、同理心、诚信、合作。</div>
        </div>
        <div>
          <div class="title1">愿景</div>
          <div class="text1">用科技创新、智能制造，将关爱融入产品，展现科技之美、呵护亿万家庭用水健康，创造美好生活。</div>
        </div>
      </div>
    </div>
    <div class="develop">
      <div class="title">品牌发展历程</div>
      <!-- 前四年历程 -->
      <div class="coures">
        <!-- 2007 -->
        <div class="course_box">
          <div class="line_box">
            <div class="circle">
              <div class="point"></div>
            </div>
            <div class="line" style="height:628rem;"></div>
          </div>
          <div class="list">
            <div class="year">2007</div>
            <div class="event">
              Aiberle成立，致力于用科技改变生活，在生活安全健康领域打造卓越的产品。同期，在美国成立研发中心，先进技术指导中国区生产，中国制造，赶超国际标准。
            </div>
            <img :src="splitImageHttp('metapure/static/mobile/about/year2007.png')" alt="">
          </div>
        </div>
        <!-- 2014 -->
        <div class="course_box">
          <div class="line_box">
            <div class="circle">
              <div class="point"></div>
            </div>
            <div class="line" style="height:543rem;"></div>
          </div>
          <div class="list">
            <div class="year">2014</div>
            <div class="event">
              Aiberle爱贝源成立全球营销总部——深圳爱贝源科技有限公司
            </div>
            <img :src="splitImageHttp('metapure/static/mobile/about/year2014.png')" alt="">
          </div>
        </div>
        <!-- 2015 -->
        <div class="course_box">
          <div class="line_box">
            <div class="circle">
              <div class="point"></div>
            </div>
            <div class="line" style="height:502rem;"></div>
          </div>
          <div class="list">
            <div class="year">2015</div>
            <div class="event">
              关注母婴群体，关注宝宝饮水安全与冲奶健康。
            </div>
            <img :src="splitImageHttp('metapure/static/mobile/about/year2015.png')" alt="">
          </div>
        </div>
        <!-- 2016 -->
        <div class="course_box">
          <div class="line_box">
            <div class="circle">
              <div class="point"></div>
            </div>
            <div class="line" style="height:502rem;"></div>
          </div>
          <div class="list">
            <div class="year">2016</div>
            <div class="event">
              关注母婴群体，关注宝宝饮水安全与冲奶健康。
            </div>
            <img :src="splitImageHttp('metapure/static/mobile/about/year2016.png')" alt="">
          </div>
        </div>
      </div>
      <!-- 后续历程 -->
      <div class="course two" v-show="state">
        <!-- 2017_1 -->
        <div class="course_box">
          <div class="line_box">
            <div class="circle">
              <div class="point"></div>
            </div>
            <div class="line" style="height:544rem;"></div>
          </div>
          <div class="list">
            <div class="year">2017</div>
            <div class="event">
              爱贝源桌面净饮机正式上市，以行业最高标准，驱动品牌持续创新。
            </div>
            <img :src="splitImageHttp('metapure/static/mobile/about/year2017_1.png')" alt="">
          </div>
        </div>
        <!-- 2017_2 -->
        <div class="course_box">
          <div class="line_box">
            <div class="circle">
              <div class="point"></div>
            </div>
            <div class="line" style="height:628rem;"></div>
          </div>
          <div class="list">
            <div class="year">2017</div>
            <div class="event">
              Aiberle爱贝源历经层层筛选被联合国教科文组织授予官方合作品牌，荣登国际舞台，不仅彰显了其作为高端生活家电的卓绝品牌实力，更体现了国际组织对于爱贝源品质的充分信赖。
            </div>
            <img :src="splitImageHttp('metapure/static/mobile/about/year2017_2.png')" alt="">
          </div>
        </div>
        <!-- 2018 -->
        <div class="course_box">
          <div class="line_box">
            <div class="circle">
              <div class="point"></div>
            </div>
            <div class="line" style="height:544rem;"></div>
          </div>
          <div class="list">
            <div class="year">2018</div>
            <div class="event">
              品牌团队重组，结合市场发展情况，大力转型并开启了新的赛道。
            </div>
            <img :src="splitImageHttp('metapure/static/mobile/about/year2018.png')" alt="">
          </div>
        </div>
        <!-- 2019 -->
        <div class="course_box">
          <div class="line_box">
            <div class="circle">
              <div class="point"></div>
            </div>
            <div class="line" style="height:544rem;"></div>
          </div>
          <div class="list">
            <div class="year">2019</div>
            <div class="event">
              全面开启主流电商+新媒体渠道二合一的发展模式。
            </div>
            <img :src="splitImageHttp('metapure/static/mobile/about/year2019.png')" alt="">
          </div>
        </div>
        <!-- 2020 -->
        <div class="course_box">
          <div class="line_box">
            <div class="circle">
              <div class="point"></div>
            </div>
            <div class="line" style="height:544rem;"></div>
          </div>
          <div class="list">
            <div class="year">2020</div>
            <div class="event">
              主流电商全面发力，爱贝源在净饮机品类里面占领一席之地。
            </div>
            <img :src="splitImageHttp('metapure/static/mobile/about/year2020.png')" alt="">
          </div>
        </div>
        <!-- 2021 -->
        <div class="course_box">
          <div class="line_box">
            <div class="circle">
              <div class="point"></div>
            </div>
            <div class="line" style="height:552rem;"></div>
          </div>
          <div class="list">
            <div class="year">2021</div>
            <div class="event">
              爱贝源全力占据品类位置，产品线成熟并走上了快速发展之路。
            </div>
            <img :src="splitImageHttp('metapure/static/mobile/about/year2021.png')" alt="">
          </div>
        </div>
      </div>
      <!-- 查看更多 -->
      <div class="coures third">
        <div class="course_box">
          <div class="line_box">
            <div class="circle">
              <div class="point"></div>
            </div>
          </div>
          <div class="more" @click="showing(state)">
            <div>{{ state === false?'查看更多':'收起' }}</div>
            <div class="show"><img :src="splitImageHttp(state === false ? 'metapure/static/mobile/about/show.png':'metapure/static/mobile/about/hide.png')"
                alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="map">
      <div class="title">以人为本 研发引领</div>
      <div class="text">
        <div class="item">
          <div class="number">30<sup>+</sup></div>
          <div class="attr">研发技术人员</div>
        </div>
        <div class="item">
          <div class="number">15<sup>+</sup></div>
          <div class="attr">出口国家和地区</div>
        </div>
        <div class="item">
          <div class="number">30<sup><span>万</span>+</sup></div>
          <div class="attr">全球用户覆盖</div>
        </div>
      </div>
      <div class="pdcenter">
        <div class="title">智能制造中心</div>
        <div class="dongguan">
          <div class="place">中国东莞</div>
          <div class="line"></div>
          <div class="circle">
            <div class="point"></div>
          </div>
        </div>
        <div class="anshan">
          <div class="place">中国马鞍山</div>
          <div class="line"></div>
          <div class="circle">
            <div class="point"></div>
          </div>
        </div>
        <div class="shenzhen">
          <div class="circle">
            <div class="point"></div>
          </div>
          <div class="line"></div>
          <div class="place">中国深圳</div>
        </div>
      </div>
    </div>
    <navBt></navBt>
  </div>
</template>
<script>
import navTop from "@/components/mobile/mobileTop.vue";
import navBt from "@/components/mobile/mobileBt.vue";

export default {
  name: 'mAbout',
  components: {
    navTop,
    navBt,
  },
  data() {
    return {
      state: false
    }
  },
  methods: {
    showing(state) {
      this.state = !state;
    }
  },
};
</script>
<style lang="less" scoped>
.mAbout {
  width: 100%;
  min-height: 100vh;
  /*min-height: 104.70rem;*/
  background: #F5F7FA;
  margin: 0 auto;

  .culture {
    position: relative;
    width: 100%;
    height: 1013rem;
    background: url('https://cdns.iamberry.com/metapure/static/mobile/about/culture.png') no-repeat center;
    background-size: 750rem 1013rem;
    box-sizing: border-box;
    padding: 72rem 0 0 46rem;
    margin: 0 auto;


    .title {
      font-size: 48rem;
      font-weight: 700;
      color: #242933;
      line-height: 72rem;

    }

    .line {
      width: 73rem;
      height: 4rem;
      background: #242933;
      margin: 48rem 0 26rem 0;
    }

    .text {
      font-size: 24rem;
      font-weight: normal;
      color: #242933;
      line-height: 36rem;
    }

    .content {
      position: absolute;
      top: 969rem;
      left: 0;
      width: 100%;
      height: 649rem;
      background: #FFFFFF;
      border-radius: 32rem;
      margin: 0 auto;
      z-index: 99;
      box-sizing: border-box;
      padding: 56rem 46rem;

      .title1 {
        font-size: 32rem;
        font-weight: 600;
        color: #242933;
        line-height: 50rem;
      }

      .text1 {
        font-size: 28rem;
        font-weight: normal;
        color: #5B5F68;
        line-height: 37rem;
        padding: 20rem 0 72rem 0;
      }
    }
  }

  .develop {
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0 46rem;

    .title {
      margin: 733rem 0 79rem 0;
      text-align: center;
      font-size: 40rem;
      font-weight: 700;
      color: #242933;
      line-height: 64rem;
    }

    .course_box {
      box-sizing: border-box;
      width: 614rem;
      display: flex;

      .line_box {
        box-sizing: border-box;
        padding-right: 24rem;

        .circle {
          width: 20rem;
          height: 20rem;
          border: 2rem solid #D3E5F1;
          border-radius: 50%;

          .point {
            width: 14rem;
            height: 14rem;
            border-radius: 50%;
            background: #69B3E7;
            margin: 3rem;
          }
        }

        .line {
          width: 2rem;
          background: #D3E5F1;
          margin-left: 10rem;

        }
      }

      .list {
        display: flex;
        flex-direction: column;

        .year {
          font-size: 32rem;
          font-weight: normal;
          color: #242933;
          line-height: 26rem;
        }

        .event {
          margin: 20rem 0 24rem 0;
          font-size: 28rem;
          font-weight: normal;
          color: #242933;
          line-height: 42rem;

        }

        img {
          width: 614rem;
          height: 346rem;
        }
      }

    }

    .third {
      display: flex;
      margin-bottom: 128rem;

      .more {
        margin-top: -10rem;
        display: flex;
        align-items: center;
        font-size: 28rem;
        font-weight: normal;
        color: #242933;
        line-height: 42rem;

        div {
          margin-right: 15rem;
        }

        img {
          width: 32rem;
          height: 22rem;
          cursor: pointer;
        }
      }
    }
  }

  .map {
    width: 100%;
    background: url('https://cdns.iamberry.com/metapure/static/mobile/about/map_1.png') no-repeat top center;
    background-size: 750rem 766rem;
    box-sizing: border-box;
    padding-top: 84rem;
    margin: 0 auto;
    text-align: center;

    .title {
      font-size: 40rem;
      font-weight: 500;
      color: #FAFBFC;
      line-height: 64rem;
    }

    .text {
      display: flex;
      margin: 60rem 46rem 129rem;
      justify-content: space-between;

      .number {
        font-size: 100rem;
        font-weight: normal;
        color: #FAFBFC;
        line-height: 158rem;

        sup {
          font-size: 36rem;
          font-weight: normal;
          color: #FAFBFC;
          line-height: 47rem;

          span {
            font-size: 24rem;
            font-weight: normal;
            color: #FAFBFC;
            line-height: 32rem;
          }
        }
      }

      .attr {
        font-size: 20rem;
        font-weight: normal;
        color: #FFFFFF;
        line-height: 26rem;
      }
    }

    .pdcenter {
      position: relative;
      width: 100%;
      height: 1041rem;
      background: url('https://cdns.iamberry.com/metapure/static/mobile/about/map_2.png') no-repeat center;
      background-size: 750rem 1041rem;
      box-sizing: border-box;
      padding-top: 95rem;

      .title {
        font-size: 32rem;
        font-weight: 700;
        color: #242933;
        line-height: 50rem;

      }

      .place {
        font-size: 24rem;
        font-weight: 550;
        color: #242933;
        line-height: 32rem;
        text-align: left;
      }

      .line {
        width: 2rem;
        background: #00A3E0;
        margin-left: 16rem;
      }

      .circle {
        width: 32rem;
        height: 32rem;
        border: 2rem solid #00A3E0;
        border-radius: 50%;

        .point {
          width: 22rem;
          height: 22rem;
          border-radius: 50%;
          background: #00A3E0;
          margin: 5rem;
        }
      }
    }
  }
}

.dongguan {
  position: absolute;
  top: 370rem;
  left: 410rem;

  .place {
    margin-bottom: 12rem;
    margin-left: -63rem;
  }

  .line {
    height: 124rem;
  }
}

.anshan {
  position: absolute;
  top: 325rem;
  left: 458rem;

  .place {
    margin-bottom: 12rem;
    margin-left: 0;
  }

  .line {
    height: 124rem;
  }
}

.shenzhen {
  position: absolute;
  top: 561rem;
  left: 452rem;

  .place {
    margin-top: 12rem;
    margin-left: 0;
  }

  .line {
    height: 97rem;
  }
}
</style>