<template>
  <div class="mobileBt">
    <div class="proCenter">
      <div class="proCenterTitle" v-for="(item, index) in btProNavList" :key="index">
        <div class="navbtct">
          <span>{{ item.name }}</span>
          <div class="imgbox">
            <img @click.stop="choseSwich(index)"
            :src="splitImageHttp(choseIndex === index ? 'metapure/static/mobile/navTop/bton.png' : 'metapure/static/mobile/navTop/btoff.png')"
            alt="" />
          </div>
        </div>
        <span v-show="choseIndex === index" @click="chosePro(index2, index, item2.url)" class="pronavlink"
          v-for="(item2, index2) in item.children" :key="index2">{{ item2.name }}</span>
      </div>
      <div class="proCenterTitle">
        <div class="navbtct">
          <span>关于我们</span>
          <div class="imgbox">
            <img :class="{roate: swichAboutShow}" :src="splitImageHttp('metapure/static/mobile/navTop/btright.png')" alt="" @click="swichAbout" />
          </div>
        </div>
        <span v-show="swichAboutShow" @click="transform(item.path)" class="pronavlink" v-for="(item, index) in about"
          :key="index">{{ item.name }}</span>
      </div>
      <div class="icoTitle icoTitle2">
        <img :src="splitImageHttp('metapure/static/mobile/navTop/btPhone.png')" alt="" />
        <span>全国客户服务热线</span>
      </div>
      <a class="icopp" href="tel:4008006692">400 800 6692</a>
      <div class="icoTitle">
        <img :src="splitImageHttp('metapure/static/mobile/navTop/btemail.png')" alt="" />
        <span>全国客户服务邮箱</span>
      </div>
      <p class="icopp">aiberle@iamberry.com</p>
      <div class="icoTitle">
        <img :src="splitImageHttp('metapure/static/mobile/navTop/btguanzhu.png')" alt="" />
        <span>关注爱贝源</span>
      </div>
      <div class="guanzhu">
        <img @click="downCode(weixingongzhonghao2, '微信公众号二维码.png')" :src="splitImageHttp('metapure/static/mobile/navTop/btwx.png')"
          alt="" />
        <img @click="downCode(tberweima2, '淘宝店铺二维码.png')" :src="splitImageHttp('metapure/static/mobile/navTop/bttb.png')"
          alt="">
        <img @click="downCode(jderweima2, '京东店铺二维码.png')"
          :src="splitImageHttp('metapure/static/mobile/navTop/btjd.png')" alt="">
      </div>
      <div class="hrbt"></div>
      <p class="beian">
        Copyright © 2016-2018 aiberle Corporation, All Rights Reserved｜深圳爱贝源科技有限公司 版权所有｜粤ICP备16023001号-2
      </p>
    </div>
    <el-dialog :visible.sync="centerDialogVisible" width="75%" :show-close="false" :modal-append-to-body="false" :close-on-click-modal="false" :center="true">
      <img class="code" :src="imgurl" alt="">
      <span slot="footer" class="dialog-footer">
        <div class="btton" @click="downLoadImg(imgurl, codeName)">下载二维码</div>
      </span>
      <img class="postionClose" @click="()=>{this.centerDialogVisible= false}"  :src="splitImageHttp('metapure/static/mobile/contact/close.png')" alt="">
    </el-dialog>
    <img v-show="topShow" class="gotop"  :src="splitImageHttp('metapure/static/images/goTop.png')" alt="" @click="gotop()">
  </div>
</template>
<script>
import jderweima2 from '@/assets/jderweima2.png'
import tberweima2 from '@/assets/tberweima2.png'
import weixingongzhonghao2 from '@/assets/weixingongzhonghao2.png'
export default {
  data() {
    return {
      jderweima2: jderweima2,
      tberweima2: tberweima2,
      weixingongzhonghao2: weixingongzhonghao2,
      imgurl: '',
      codeName: '',
      topShow: false,
      centerDialogVisible: false,
      choseIndex: null,
      swichAboutShow: false,
      about: [{ name: '公司简介', path: '/mobile/mAbout' }, { name: '联系我们', path: '/mobile/mContact' }],
      btProNavList: [
        {
          name: "产品中心",
          children: [
            { name: "H矿化系列" },
            { name: "R复古系列" },
            { name: "C时尚系列" },
            { name: "富氢系列" },
            { name: "健康爱护系列" },
          ],
        },
        {
          name: "体验与服务",
          children: [
            { name: '官方商店', url: 'https://aiberle.tmall.com/?ali_refid=a3_430582_1006:1220320044:N:YThn%2ByvB%2FNowajv9sSeocg%3D%3D:10eebd944a8e52b36c0f999ad80761a4&amp;ali_trackid=1_10eebd944a8e52b36c0f999ad80761a4&amp;spm=a230r.1.14.4' },
            { name: '选购配件', url: 'https://aiberle.tmall.com/category-1454909179.htm?spm=a1z10.1-b-s.w5001-23855085050.6.4a1376d4vOpCvs&amp;search=y&amp;catName=%C2%CB%D0%BE%2F%C5%E4%BC%FE&amp;scene=taobao_shop' }
          ],
        },
      ],
    };
  },
  mounted(){
    window.addEventListener('scroll',this.handleScroll)
  },
  methods: {
    handleScroll(){
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if(scrollTop>0){
        this.topShow = true
        return
      }
      this.topShow = false
    },
    gotop(){
      window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    },
    downCode(url, name) {
      this.centerDialogVisible = true
      this.imgurl = url
      this.codeName = name
    },
    getUrlBase64(url) {
      return new Promise(resolve => {
        let canvas = document.createElement('canvas')
        let ctx = canvas.getContext('2d')
        let img = new Image()
        img.crossOrigin = 'anonymous' //允许跨域
        img.src = url
        img.onload = function () {
          canvas.height = 300
          canvas.width = 300
          ctx.drawImage(img, 0, 0, 300, 300)
          let dataURL = canvas.toDataURL('image/png')
          canvas = null
          resolve(dataURL)
        }
      })
    },
    // 图片下载
    downLoadImg(url, filename) {
      this.getUrlBase64(url).then(base64 => {
        let link = document.createElement('a')
        link.href = base64
        link.download = filename
        link.click()
      })
    },
    swichAbout() {
      if (!this.swichAboutShow) {
        this.choseIndex = null
      }
      this.swichAboutShow = !this.swichAboutShow
    },
    chosePro(index, key, url) {
      if (key === 1) {
        window.open(url)
        return
      }
      window.scrollTo(0, 0)
        // console.log(index,'index')
      this.$eventBus.$emit('choseproduct', index)
    },
    transform(path){
        if(this.$route.path===path) return
        this.$router.push({path: path})
    },
    choseSwich(key) {
      if (this.choseIndex === key) {
        this.choseIndex = null;
        return;
      }
      this.choseIndex = key;
      this.swichAboutShow = false
    },
  },
};
</script>
<style lang="less" scoped>
.gotop{
  position: fixed; 
  bottom: 180rem;
  right: 32rem;
  width: 112rem;
  height: 112rem;
  z-index: 10;
}
.postionClose{
  position: absolute;
  width: 68rem;
  height: 68rem;
  bottom: -120rem;
  left: 43%;
}
.btton{
    width: 240rem;
    height: 80rem;
    background: #69B3E7;
    border-radius: 50rem;
    margin: 0 auto;
    color: #ffffff;
    line-height: 80rem;
    font-size: 24rem;
    margin-bottom: 40rem;
}
/deep/ .el-dialog--center{
    border-radius: 20rem;
}
/deep/ .el-dialog__header{
    padding: 0 !important;
}
/deep/ .el-dialog--center .el-dialog__body{
    padding: 25rem 25rem 0 30rem !important;
}
/deep/ .v-modal{
  opacity: 0.7 !important;
}
/deep/ .el-dialog{
  z-index: 9999;
  margin-top: 20vh !important;
}
/deep/ .el-dialog__footer{
  padding: 10rem 20rem 1rem 20rem;
}
.roate{
  transform: rotate(90deg);
}
.code {
  width: 400rem;
  margin: 30rem auto;
}

.hrbt {
  width: 686rem;
  height: 2rem;
  background: #5B5B5B;
  margin: 0 auto;
  margin-top: 56rem;
}

.beian {
  display: block;
  margin: 0 auto;
  margin-top: 30rem;
  width: 686rem;
  white-space: normal;
  word-break: break-all;
  color: #959595;
  font-size: 16rem;
  line-height: 34rem;
}

.mobileBt {
  width: 100%;
  min-height: 960rem;
  background-image: url("https://cdns.iamberry.com/metapure/static/mobile/navTop/btbg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-sizing: border-box;
  padding-top: 24rem;
  /*隐藏购买按钮*/
  position: relative;
  z-index: 99;

  .proCenter {
    width: 100%;
    box-sizing: border-box;
    padding: 0 32rem;

    .icopp {
      color: #ffffff;
      font-size: 38rem;
      line-height: 56rem;
      margin-top: 4rem;
      padding-left: 56rem;
      display: block;
      // font-weight: bold;
    }

    .icoTitle2 {
      margin-top: 56rem !important;
    }

    .guanzhu {
      padding-left: 48rem;
      display: flex;
      align-items: center;
      margin-top: 20rem;

      img {
        width: 63rem;
        height: 47rem;
        margin-right: 36rem;
      }
    }

    .icoTitle {
      display: flex;
      align-items: center;
      margin-top: 40rem;

      img {
        width: 35rem;
        height: 35rem;
        margin-right: 20rem;
      }

      span {
        color: #959595;
        font-size: 28rem;
        line-height: 32rem;
      }
    }

    .proCenterTitle {
      width: 686rem;
      min-height: 104rem;
      border-bottom: 2rem solid #5b5b5b;

      .pronavlink {
        display: block;
        padding-bottom: 44rem;
        color: #959595;
        font-size: 24rem;
        line-height: 26rem;
      }

      .navbtct {
        width: 100%;
        height: 109rem;
        box-sizing: border-box;
        padding-right: 16rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .imgbox{
          width: 80rem;
          height: 80rem;
          display: flex;
          align-items: center;
          flex-direction:row-reverse
        }
        span {
          color: #ffffff;
          font-size: 28rem;
          line-height: 38rem;
        }

        img {
          width: 20rem;
          height: 20rem;
        }
      }
    }
  }
}
</style>