<template>
  <div class="banner">
    <swiper ref="mySwiper" class="swiper swiper-no-swiping" :options="options">
      <swiper-slide class="swiperct " v-for="(item, index) in pouductList" :key="index"  @click.stop="">
        <img @click="productDetail(item.img[item.key].id, item.series, item.product)" :src="splitImageHttp(item.img[item.key].src)" alt="">
        <p class="title">{{ item.name }}</p>
        <div class="chosedos">
          <div class="waic" v-for="(item2, index2) in item.img" :key="index2" :class="{ showborder: index2 === item.key }"
            @click="changes(index, index2)">
            <p class="neic" :style="{ 'background': item2.color }"></p>
          </div>
          <span>{{ item.img[item.key].name }}</span>
        </div>
      </swiper-slide>
      <!-- <div class="swiper-pagination" slot="pagination"></div> -->
    </swiper>
    <img class="lr btn-left" :src="splitImageHttp('metapure/static/pcImg/leftL.png')" alt="">
    <img class="lr btn-right" :src="splitImageHttp('metapure/static/pcImg/rightL.png')" alt="">
  </div>
</template>
<script>
let vm = null
export default {
  data() {
    return {
      pouductList: [{color: '', name: '富氢系列-Modern 5', series: '富氢系列', product: 'Modern 5', key: 0, img: [{id: '8', name: '雾海蓝', src: 'metapure/static/pcImg/hot-img-1.png', color: 'rgba(92, 118, 143, 1)' }] },
      {color: '', name: 'C时尚系列-Convenient 1', series: 'C时尚系列', product: 'Convenient 1', key: 0, img: [{id: '6', name: '云水蓝', src: 'metapure/static/pcImg/hot-img-2.png', color: 'rgba(225, 231, 235, 1)' }] },
      {color: '',name: 'H矿化系列-岩泉 Healthy 7', series: 'H矿化系列', product: 'Healthy 7', key: 0,img: [{id: '2', name: '深空灰', src: 'metapure/static/pcImg/hot-img-3_g.png', color: 'rgba(113, 113, 113, 1)' },{id: '1', name: '净息白', src: 'metapure/static/pcImg/hot-img-3_w.png', color: 'rgba(216, 216, 216, 1)' }]},
      {color: '',name: 'R复古系列-Retro 3', series: 'R复古系列', product: 'Retro 3', key: 0, img:[{id: '4', name: '奶油白', src: 'metapure/static/pcImg/hot-img-4_wh.png', color: 'rgba(247, 240, 230, 1)' },{id: '3', name: '玛瑙蓝', src: 'metapure/static/pcImg/hot-img-4_bl.png', color: 'rgba(200, 219, 224, 1)' },{id: '5', name: '陨石灰', src: 'metapure/static/pcImg/hot-img-4_g.png', color: 'rgba(124, 124, 125, 1)' }]},
      ],
      imgIndex: 0,
      //配置项例子
      options: {
        slidesPerView: "auto",
				autoplay: false, //设置为true启动自动切换，并使用默认的切换设置。
        loop: false,
        freeMode: false,
        navigation: {
          //下一张
          nextEl: '.btn-right',//下一张标签类名可以自定义
          //上一张
          prevEl: '.btn-left'//上一张标签类名可以自定义
        },
        on: {
          // click: function(e){
          //   if(!e.target.dataset.path) return
          //   vm.productDetail(e.target.dataset.path, e.target.dataset.series, e.target.dataset.product)
          // }
        }
      },
    };
  },
  created(){
    vm = this
  },
  methods: {
    changes(index, index2) {
      // console.log(index, index2)
      this.pouductList[index].key = index2
    },
    handelclick(key1,key2){
      console.log(key1,key2)
    },
    productDetail(id,xilie,name) {
      this.$router.push({path: '/home/introduce', query: {id: id, series: xilie, name: name}})
    },
  }
};
</script>
<style lang="less" scoped>
/deep/ .swiper-button-disabled{
  opacity: 0.4 !important;
}
.banner {
  width: 100%;
  min-height: 520px;
  overflow: hidden;
  position: relative;
}
.lr{
  display: block;
  position: absolute;
  width: 50px;
  height: 50px;
  z-index: 9999;
  cursor: pointer;
}
.btn-left{
  left: 0px;
  top: 180px;
}
.btn-right{
  right: 0px;
  top: 180px;
}

.swiper {
  width: 1314px;
  margin: 0 auto;
  height: 520px;
  // overflow: visible !important;

  // margin: 0 left;
  .swiperct {
    width: 438px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      margin-top: 32px;
      margin-bottom: 24px;
      color: #FFFFFF;
      font-size: 24px;
      line-height: 38px;
    }

    .chosedos {
      display: flex;
      align-items: center;
      justify-content: center;

      .showborder {
        border: 1px solid #CBCBCB;
      }

      .neic {
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }

      .waic {
        width: 20px;
        height: 20px;
        padding: 1px;
        margin-right: 31px;
        border-radius: 50%;
        z-index: 9999;
        cursor: pointer;
      }

      span {
        color: rgba(203, 203, 203, 1);
        font-size: 20px;
        line-height: 26px;
      }
    }

    img {
      width: 400px;
      height: 400px;
      cursor: pointer;
    }
  }
}
</style>
