import { render, staticRenderFns } from "./mobileBt.vue?vue&type=template&id=b34de50a&scoped=true&"
import script from "./mobileBt.vue?vue&type=script&lang=js&"
export * from "./mobileBt.vue?vue&type=script&lang=js&"
import style0 from "./mobileBt.vue?vue&type=style&index=0&id=b34de50a&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b34de50a",
  null
  
)

export default component.exports