<template>
    <div class="bt">
        <div class="btc">
            <div class="btc_product">
                <span class="product_title">了解产品</span>
                <span class="product_ms" v-for="(item, index) in product" :key="index"
                    @click="transforms(item.id, item.xilie, item.name)">{{
                        item.name }}</span>
            </div>
            <div class="btc_product" style="margin-left:92px">
                <span class="product_title">体验与服务</span>
                <a :href="item.path" target="_blank" class="product_ms" v-for="(item, index) in Service" :key="index">{{
                    item.name }}</a>
            </div>
            <div class="btc_product" style="margin-left:120px">
                <span class="product_title">关于我们</span>
                <span class="product_ms" v-for="(item, index) in About" :key="index" @click="transform(item.path)">{{
                    item.name }}</span>
            </div>
            <div class="product_right">
                <div class="contact">
                    <div class="contact_title">
                        <img src="@/assets/phone.png" alt="">
                        <span>全国客服热线服务</span>
                    </div>
                    <span class="contact_fs">400 800 6692</span>
                </div>
                <div class="contact">
                    <div class="contact_title">
                        <img src="@/assets/email.png" alt="">
                        <span>全国客户服务邮箱</span>
                    </div>
                    <span class="contact_fs">aiberle@iamberry.com</span>
                </div>
                <div class="contact" style="margin-top:54px">
                    <div class="contact_title">
                        <img src="@/assets/add.png" alt="">
                        <span>关注爱贝源</span>
                    </div>
                    <div class="contact_fss">
                        <img @mouseenter="handleMouseEnter(1)" @mouseleave="handleMouseLeave(1)"
                            :src="splitImageHttp(wxsrc)" alt="">
                        <img @mouseenter="handleMouseEnter(2)" @mouseleave="handleMouseLeave(2)"
                            :src="splitImageHttp(tbsrc)" alt="">
                        <img @mouseenter="handleMouseEnter(3)" @mouseleave="handleMouseLeave(3)"
                            :src="splitImageHttp(jdsrc)" alt="">
                        <div class="qrCode wx" v-show="wxShow">
                            <img :src="splitImageHttp('metapure/static/pcImg/weixingongzhonghao.png')" alt="">
                        </div>
                        <div class="qrCode tb" v-show="tbShow">
                            <img :src="splitImageHttp('metapure/static/pcImg/tberweima.png')" alt="">
                        </div>
                        <div class="qrCode jd" v-show="jdShow">
                            <img :src="splitImageHttp('metapure/static/pcImg/jderweima.png')" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="btc_btabout">
                <div class="hr"></div>
                <a href="https://beian.miit.gov.cn/#/Integrated/index">Copyright © 2016-2018 aiberle Corporation, All Rights
                    Reserved｜深圳爱贝源科技有限公司 版权所有｜粤ICP备16023001号-2</a>
            </div>
        </div>
        <img v-show="topShow" class="gotop" :src="splitImageHttp('metapure/static/images/goTop.png')" alt=""
            @click="gotop()">
    </div>
</template>
<script>
export default {
    data() {
        return {
            product: [
                { id: '6', name: 'Convenient 1', xilie: 'C时尚系列' },
                { id: '4', name: 'Retro 3', xilie: 'R复古系列' },
                { id: '8', name: 'Modern 5', xilie: '富氢系列' },
                { id: '1', name: 'Healthy 7', xilie: 'H矿化系列' },
                { id: '7', name: 'WA-1富氢净饮', xilie: '富氢系列' },
                { id: '9', name: '挂耳咖啡机', xilie: '健康爱护系列' },
                { id: '10', name: '软水机', xilie: '健康爱护系列' },
                { id: '11', name: '前置过滤器', xilie: '健康爱护系列' },
                { id: '12', name: 'N02花洒', xilie: '健康爱护系列' },
                { id: '13', name: 'N03花洒', xilie: '健康爱护系列' }
            ],
            Service: [
                { name: '官方商店', path: 'https://aiberle.tmall.com/?ali_refid=a3_430582_1006:1220320044:N:YThn%2ByvB%2FNowajv9sSeocg%3D%3D:10eebd944a8e52b36c0f999ad80761a4&amp;ali_trackid=1_10eebd944a8e52b36c0f999ad80761a4&amp;spm=a230r.1.14.4' },
                { name: '选购配件', path: 'https://aiberle.tmall.com/category-1454909179.htm?spm=a1z10.1-b-s.w5001-23855085050.6.4a1376d4vOpCvs&amp;search=y&amp;catName=%C2%CB%D0%BE%2F%C5%E4%BC%FE&amp;scene=taobao_shop' }
            ],
            About: [
                { name: '公司简介', path: '/About' },
                { name: '联系我们', path: '/Contact' }
            ],
            wxShow: false,
            tbShow: false,
            jdShow: false,
            wxsrc: 'metapure/static/pcImg/wx.png',
            tbsrc: 'metapure/static/pcImg/tb.png',
            jdsrc: 'metapure/static/pcImg/jd.png',
            topShow: false,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll)
    },
    methods: {
        gotop() {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        },
        handleScroll() {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            if (scrollTop > 0) {
                this.topShow = true
                return
            }
            this.topShow = false
        },
        transform(path) {
            if (this.$route.path === path) return 
            this.$router.push({ path: path })
        },
        handleMouseEnter(key) {
           // console.log(key)
            if (key === 1) {
                this.wxShow = true
                this.wxsrc = 'metapure/static/pcImg/iconwx2.png'
                // console.log('ssss')
                return
            }
            if (key === 2) {
                this.tbShow = true
                this.tbsrc = 'metapure/static/pcImg/icontb2.png'
                return
            }
            this.jdShow = true
            this.jdsrc = 'metapure/static/pcImg/iconjd2.png'
        },
        handleMouseLeave(key) {
            if (key === 1) {
                this.wxShow = false
                this.wxsrc = 'metapure/static/pcImg/wx.png'
                return
            }
            if (key === 2) {
                this.tbShow = false
                this.tbsrc = 'metapure/static/pcImg/tb.png'
                return
            }
            this.jdShow = false
            this.jdsrc = 'metapure/static/pcImg/jd.png'
        },
        transforms(id, xilie, name) {
            if (this.$route.path === '/home/introduce') {
                window.location.href = window.location.origin + `/home/introduce?id=${id}&series=${xilie}&name=${name}`
                return
            }
            this.$router.push({ path: '/home/introduce', query: { id: id, series: xilie, name: name } })
        }
    }
}
</script>
<style lang="less" scoped>
.gotop {
    position: fixed;
    right: 32px;
    width: 67px;
    height: 67px;
    bottom: 300px;
    cursor: pointer;
}

.bt {
    width: 100%;
    min-width: 1440px;
    background: #242933;
}

.btc {
    width: 1440px;
    margin: 0 auto;
    height: 576px;
    box-sizing: border-box;
    padding-left: 180px;
    padding-top: 98px;
    display: flex;
    position: relative;

    .btc_product {
        display: flex;
        flex-direction: column;

        .product_title {
            color: #FFFFFF;
            font-size: 16px;
            line-height: 25px;
            margin-bottom: 29px;
        }

        .product_ms {
            color: #959595;
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 14px;
            cursor: pointer;
        }
    }

    .product_right {
        display: flex;
        flex-direction: column;
        margin-left: 382px;

        .contact {
            margin-bottom: 34px;

            .contact_title {
                color: #959595;
                font-size: 14px;
                line-height: 18px;
                display: flex;
                align-items: center;
                margin-bottom: 4px;

                img {
                    width: 20px;
                    height: 20px;
                    margin-right: 12px;
                }
            }

            .contact_fs {
                color: #FFFFFF;
                font-size: 18px;
                line-height: 29px;
                margin-left: 32px;
            }

            .contact_fss {
                display: flex;
                padding-left: 32px;
                align-items: center;
                margin-top: 8px;
                position: relative;
                cursor: pointer;

                .wx {
                    left: -8px;
                    bottom: 40px;
                }

                .tb {
                    left: 40px;
                    bottom: 40px;
                }

                .jd {
                    left: 89px;
                    bottom: 40px;
                }

                .qrCode {
                    width: 140px;
                    height: 169px;
                    position: absolute;

                    img {
                        width: 140px;
                        height: 169px;
                        margin-left: 0;
                    }
                }

                img {
                    width: 32px;
                    height: 32px;
                    margin-left: 16px;
                }
            }
        }
    }

    .btc_btabout {
        position: absolute;
        width: 1080px;
        height: 72px;
        left: 180px;
        bottom: 0px;

        .hr {
            width: 1080px;
            height: 1px;
            background: rgba(91, 91, 91, 1);
            margin-bottom: 27px;
        }

        a {
            color: #959595;
            line-height: 16px;
            font-size: 12px;
        }
    }
}
</style>