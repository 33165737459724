import { post } from '@/commom/request'
const config = {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
}
// const config2 = {
//   headers: {
//     'Content-Type': 'application/x-www-form-urlencoded'
//   }
// }
const apiUrl = {
  home: '/index/home'
}

const info = {
  namespaced: true,
  state: {
    info: {}
  },
  getters: {
    user: ({ info }) => info
  },
  mutations: {
    SET_INFO: (state, info) => {
      state.info = info
    },
  },

  actions: {
    // 首页数据
    async Home({ commit }, parms) {
      const res = await post(apiUrl.home, parms, config)
      const info = res.data.SESSION
      commit('SET_INFO', info)
      return res
    }
  }
}
export default info
