<template>
  <div class="tops">
    <div class="kongbai"></div>
    <div class="top">
      <img class="img" :src="splitImageHttp('metapure/static/pcImg/logo.png')" alt="" @click="choseNav('/home')" >
      <div class="top_nav">
        <span :class="{chose: index===indexkey}" v-for="(item,indexkey) in list" :key="indexkey" @click="choseNav(item.path)">{{ item.name }}</span>
      </div>
      <!-- <img style="width:28px;heigth:28px;margin-left:70px;margin-right:41px" :src="en" alt=""> -->
      <!-- <img style="width:28px;heigth:28px" :src="ch" alt=""> -->
    </div>
  </div>
</template>

<script>
// import logo from '../../static/img/logo.png'
import en from '../../static/img/en.png'
import ch from '../../static/img/ch.png'
export default {
  name: 'NavTop',
  props: {
    index: {
      typeof: Number,
      default: 0
    }
  },
  data () {
    return {
      // logo: logo,
      en: en,
      ch: ch,
      list:[{name: '首页',path:'/home'},{name: '产品中心', path: '/home/product'},{name: '关于我们',path:'/About'},{name: '联系我们', path: '/Contact'}]
    }
  },
  methods: {
    choseNav(path){
      if(this.$route.path===path) return
      this.$router.push({path: path})
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.tops{
  width: 1440px;
  height: 90px;
  margin: 0 auto;
  position: relative;
  .kongbai{
    width: 1440px;
    height: 90px;
  }
}
.top{
  width: 1440px;
  height: 90px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-left: 180px;
  position: absolute;
  left: 0;
  top: 0;
}
.img{
    width: 170px;
    height: 25px;
    cursor: pointer;
  }
  .top_nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 462px;
    height: 90px;
    margin-left: 350px;
  }
  .chose{
    color: #69B3E7 !important;
  }
  .top_nav span{
    color: #242933;
    font-size: 18px;
    cursor: pointer;
  }
</style>
