<template>
  <div class="rem">
    <navTop></navTop>
    <!-- 轮播图 -->
    <!-- <div class="banner">
      <el-carousel height="987rem" trigger="click">
        <el-carousel-item v-for="(item, index) in banners" :key="index">
          <img style="width:100%;height:987rem" :src="splitImageHttp(item.src)" alt="">
          <div class="text" :class="{ white: index === 3 }">
            <div class="proName">{{ item.name }}</div>
            <div class="proNote">{{ item.note }}</div>
            <div class="proMore">了解产品 <img :src="splitImageHttp(item.icon)" alt=""></div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div> -->
    <mbanner></mbanner>
    <!-- 产品分类 -->
    <div class="productType">
      <div class="title">产品分类</div>
      <div class="titles">PRODUCTS CATEGORIES</div>
      <div class="product">
        <div class="product_detail" v-for="(item, index) in list" :key="index">
          <img :src="splitImageHttp(item.src)" alt="">
          <div class="product_name" @click="chosePro(index)" :key="index">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <!-- 人气产品 -->
    <div class="popular_product">
      <div class="title">人气产品</div>
      <div class="titles">POPULAR PRODUCTS</div>
      <div class="product">
        <mbautobanner></mbautobanner>
      </div>
      <div class="more" @click="transfrom(true)">查看更多</div>
    </div>
    <!-- 关于我们 -->
    <div class="about">
      <div class="title">关于我们</div>
      <div class="titles">ABOUT US</div>
      <div class="about_content">
        <div class="color1">以创新智造为驱，以关爱呵护为本,守护家庭用水健康，创造美好生活。</div>
        <div class="hr"></div>
        <div class="color2">企业使命</div>
        <div class="color3">守护生命之源，呵护家庭用水健康。</div>
        <div class="color2">核心价值观</div>
        <div class="color3">关爱、用户导向、创新设计、责任感、同理心、诚信、合作。</div>
        <div class="color2">愿景</div>
        <div class="color3">用科技创新、智能制造，将关爱融入产品，展现科技之美、呵<br />护亿万家庭用水健康，创造美好生活。</div>
      </div>
      <img class="about_left" src="https://cdns.iamberry.com/metapure/static/mobile/home/about.png"
        alt="">
    </div>
    <navBt></navBt>
  </div>
</template>
<script>
import navTop from "@/components/mobile/mobileTop.vue";
import navBt from "@/components/mobile/mobileBt.vue";
import mbautobanner from '@/components/mobile/mbautobanner.vue'
import mbanner from '@/components/mobile/mbanner.vue'

export default {
  components: {
    navTop,
    navBt,
    mbautobanner,
    mbanner,
  },
  data() {
    return {
      list: [{ name: 'H矿化系列', src: 'metapure/static/mobile/home/type_kh.png' },
      { name: 'R复古系列', src: 'metapure/static/mobile/home/type_fg.png' },
      { name: 'C时尚系列', src: 'metapure/static/mobile/home/type_ss.png' }],
      banners: [
        { name: 'Aiberle C1', src: 'metapure/static/mobile/home/banner1.png', note: '可拆卸纯水箱 | 语音提示', icon: 'metapure/static/mobile/home/icon_b.png' },
        { name: 'Aiberle Retro 3', src: 'metapure/static/mobile/home/banner2.png', note: '时尚复古 | RO多重过滤 | 即热即饮', icon: 'metapure/static/mobile/home/icon_b.png' },
        { name: '岩泉 Healthy 7', src: 'metapure/static/mobile/home/banner3.png', note: '富锶天然矿泉 | RO多重过滤 | 即热即饮', icon: 'metapure/static/mobile/home/icon_b.png' },
        { name: 'Modern 5 富氢机', src: 'metapure/static/mobile/home/banner4.png', note: '富氢水 | 即饮净水 | 健康', icon: 'metapure/static/mobile/home/icon_b.png' }
      ],
    };
  },
  methods: {
      //人气产品跳转至导航栏
    transfrom(boolean) {
      window.scrollTo(0, 0)
      this.$eventBus.$emit('seeMore', boolean)
    },
    //产品分类点击跳转都菜单
    chosePro(index) {
      window.scrollTo(0, 0)
      this.$eventBus.$emit('choseproduct', index)
    },
  }

};
</script>
<style lang="less" scoped>
.rem {
  width: 100%;
  // min-height: 100vh;
  margin: 0 auto;
  overflow: hidden;


  /* 轮播图 */
  .banner {
    position: relative;
    width: 100%;

    .white {
      color: #fff !important;
    }

    .text {
      position: absolute;
      top: 110rem;
      //left: 50%;
      color: #242933;
      text-align: center;
      //margin-left: -313rem;
      padding: 0 auto;

      .proName {
        font-size: 48rem;
        font-weight: 600;
        line-height: 76rem;
      }

      .proNote {
        font-size: 36rem;
        font-weight: normal;
        line-height: 47rem;
        margin: 16rem 0 48rem 0;
      }

      .proMore {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 28rem;
        font-weight: normal;
        line-height: 37rem;

        img {
          width: 32rem;
          height: 22rem;
          margin-left: 16rem;
        }
      }
    }

  }

  /* 产品分类 */
  .productType {
    width: 100%;
    height: 612rem;
    background: url('https://cdns.iamberry.com/metapure/static/mobile/home/type_bg.png') no-repeat center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 80rem 32rem 90rem;

    .title {
      font-size: 36rem;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 56rem;
    }

    .titles {
      font-size: 20rem;
      font-weight: normal;
      color: #717171;
      line-height: 26rem;
      margin: 16rem 0 60rem 0;
    }

    .product {
      width: 686rem;
      height: 284rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .product_detail {
        width: 216rem;
        height: 284rem;
        background: rgba(255, 255, 255, 0.08);
        border-radius: 10rem;
        backdrop-filter: blur(42rem);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
          width: 140rem;
          height: 140rem;
        }

        .product_name {
          margin-top: 21rem;
          width: 148rem;
          height: 44rem;
          background: #FFFFFF;
          border-radius: 2rem;
          font-size: 20rem;
          font-weight: normal;
          color: #242933;
          line-height: 44rem;
          text-align: center;
        }
      }
    }
  }

  /* 人气产品 */
  .popular_product {
    width: 100%;
    box-sizing: border-box;
    padding-top: 80rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(36, 41, 51, 1);

    .more {
      text-align: center;
      width: 192rem;
      height: 64rem;
      background: #FFFFFF;
      border-radius: 2rem;
      font-size: 28rem;
      color: rgba(36, 41, 51, 1);
      line-height: 64rem;
      margin-top: 64rem;
      margin-bottom: 90rem;
    }

    .title {
      font-size: 36rem;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 56rem;
    }

    .titles {
      font-size: 20rem;
      font-weight: normal;
      color: #717171;
      line-height: 26rem;
      margin-top: 16rem;
      margin-bottom: 60rem;

      .product {
        width: 100%;
        height: 688rem;
        overflow: hidden;
      }
    }
  }

  /* 关于我们 */
  .about {
    width: 100%;
    height: 1358rem;
    box-sizing: border-box;
    padding: 80rem 32rem;
    background: url('https://cdns.iamberry.com/metapure/static/mobile/home/about_bg.png') no-repeat center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-size: 36rem;
      font-weight: normal;
      color: #FFFFFF;
      line-height: 56rem;
    }

    .titles {
      font-size: 20rem;
      font-weight: normal;
      color: #717171;
      line-height: 26rem;
      margin-top: 16rem;
      margin-bottom: 50rem;
    }

    .about_content {
      width: 686rem;
      min-height: 300rem;

      .color1 {
        font-size: 28rem;
        font-weight: 600;
        color: #CBCBCB;
        line-height: 42rem;
      }

      .hr {
        width: 36rem;
        height: 4rem;
        background: #FFFFFF;
        margin: 40rem 0;
      }

      .color2 {
        font-size: 28rem;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 44rem;
      }

      .color3 {
        font-size: 24rem;
        font-weight: normal;
        color: #CBCBCB;
        line-height: 36rem;
        margin-top: 16rem;
        margin-bottom: 40rem;
      }

    }

    img {
      width: 686rem;
      height: 408rem;
      margin-top: 20rem;
    }
  }

}
</style>