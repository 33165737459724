<template>
  <div class="home">
    <navTop :index="0"></navTop>
    <div class="banner_pro">
      <swiper ref="mySwiper" class="swiper" :options="options">
        <swiper-slide class="swiperct banners1" v-for="(item, index) in banners" :key="index">
          <img style="height:710px" :data-path="item.id" :data-series="item.series" :data-product="item.name"
            :src="splitImageHttp(item.src)" alt="" />
          <div class="wenzi" :data-path="item.id" :data-series="item.series" :data-product="item.name">
            <span class="bannersp1" :data-path="item.id" :data-series="item.series" :data-product="item.name"
              :style="{ color: item.color }">{{ item.name }}</span>
            <span class="bannersp2" :data-path="item.id" :data-series="item.series" :data-product="item.name"
              :style="{ color: item.color }">{{ item.note }}</span>
            <div class="bannersp3" :data-path="item.id" :data-series="item.series" :data-product="item.name"
              :style="{ color: item.color }">
              <p :data-path="item.id" :data-series="item.series" :data-product="item.name">了解产品</p><img
                :data-path="item.id" :data-series="item.series" :data-product="item.name"
                :src="splitImageHttp(item.right === 's1' ? 'metapure/static/pcImg/iconIndexRight.png' : 'metapure/static/pcImg/iconIndexRightbai.png')"
                alt="">
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <div class="productType">
      <div class="title">产品分类</div>
      <div class="titles">PRODUCTS CATEGORIES</div>
      <div class="product">
        <div class="product_detail" v-for="(item, index) in list" :key="index">
          <img :src="item.src" alt="">
          <div class="product_name" @click="productSeries(item.key)">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div class="popular_product">
      <div class="title">人气产品</div>
      <div class="titles">POPULAR PRODUCTS</div>
      <div class="product">
        <autobanner></autobanner>
      </div>
      <div class="more" @click="seeMore">查看更多</div>
    </div>
    <div class="about">
      <div class="title">关于我们</div>
      <div class="titles">ABOUT US</div>
      <div class="about_content">
        <img class="about_left" src="https://cdns.iamberry.com/metapure/static/images/yinshui.png" alt="">
        <div class="hr"></div>
        <div class="about_right">
          <span class="color3">以创新智造为驱，以关爱呵护为本,守护家庭用水健康，创造美好生活。</span>
          <div class="hrs"></div>
          <span class="color2">企业使命</span>
          <span class="color1">守护生命之源，呵护家庭用水健康。</span>
          <span class="color2">核心价值观</span>
          <span class="color1">关爱、用户导向、创新设计、责任感、同理心、诚信、合作。</span>
          <span class="color2">愿景</span>
          <span class="color1" style="margin-bottom:0px">用科技创新、智能制造，将关爱融入产品，展现科技之美、呵护亿万家庭用水健康，创造美好生活。</span>
        </div>
      </div>
    </div>
    <navbt></navbt>

  </div>
</template>

<script>
import navTop from '@/components/NavTop'
import navbt from '@/components/navBt'
import autobanner from '@/components/autobanner'
let vm = null
export default {
  name: 'Home',
  components: {
    navTop,
    navbt,
    autobanner
  },
  data() {
    return {
      list: [{ name: 'H矿化系列', src: 'https://cdns.iamberry.com/metapure/static/images/product_h.png', key: 0 },
      { name: 'R复古系列', src: 'https://cdns.iamberry.com/metapure/static/images/product_r.png', key: 1 },
      { name: 'C时尚系列', src: 'https://cdns.iamberry.com/metapure/static/images/product_c.png', key: 2 }],
      banners: [
        { color: '#242933', name: 'Aiberle C1', src: 'metapure/static/pcImg/banner11.jpg', note: '可拆卸纯水箱 | 语音提示', id: '6', series: 'C时尚系列', right: 's1' },
        { color: '#242933', name: 'Aiberle Retro 3', src: 'metapure/static/pcImg/banner2.jpg', note: '时尚复古 | RO多重过滤 | 即热即饮', id: '3', series: 'R复古系列', right: 's1' },
        { color: '#242933', name: 'Modern 5 富氢机', src: 'metapure/static/pcImg/banner3.jpg', note: '富氢水 | 即饮净水 | 健康', id: '8', series: '富氢系列', right: 's1' },
        { color: '#FFFFFF', name: '岩泉 Healthy 7', src: 'metapure/static/pcImg/banner4.jpg', note: '富锶天然矿泉 | RO多重过滤 | 即热即饮', id: '2', series: 'H矿化系列', right: 's2' },
      ],
      options: {
        loop: true,
        autoplay: true,
        autoplay: {
          //swiper手动滑动之后自动轮播失效的解决方法,包括触碰，拖动，点击pagination,重新启动自动播放
          disableOnInteraction: false,
          // 自动播放时间：毫秒
          delay: 4000,
        },
        // freeMode: true,
        on: {
          click: function (e) {
            if (!e.target.dataset.path) return
            vm.productDetail(e.target.dataset.path, e.target.dataset.series, e.target.dataset.product)
          },
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          // bulletClass: "my-bullet",
        },
      },
    }
  },
  created() {
    vm = this
  },
  methods: {
    productDetail(id, xilie, name) {
      this.$router.push({ path: '/home/introduce', query: { id: id, series: xilie, name: name } })
    },
    seeMore() {
      this.$router.push({ path: '/home/product', query: { index: 0 } })
    },
    productSeries(key) {
      this.$router.push({ path: '/home/product', query: { index: key } })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.banner_pro {
  width: 100%;

  .swiper {
    width: 100%;
    height: 710px;
    overflow: hidden;

    /deep/ .swiper-pagination-bullet-active {
      background: #ffffff;
    }

    .swiper-pagination {
      /deep/ .swiper-pagination-bullet {
        margin: 0 12px !important;
      }
    }
  }
}

.wenzi {
  width: 1440px;
  height: 710px;
  position: absolute;
  margin: 0 auto;
}

.banners1 {
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
}

.bannersp2 {
  position: absolute;
  left: 180px;
  top: 310px;
  color: #242933;
  font-size: 32px;
  line-height: 42px;
}

.bannersp1 {
  position: absolute;
  left: 180px;
  top: 209px;
  color: #242933;
  font-size: 48px;
  line-height: 76px;
  font-weight: 700;
}

.bannersp3 {
  position: absolute;
  display: flex;
  align-items: center;
  color: #242933;
  font-size: 24px;
  line-height: 44px;
  left: 180px;
  top: 418px;
  cursor: pointer;

  img {
    width: 44px;
    height: 44px;
    margin-left: 14px;
  }
}

.home {
  width: 100%;
  min-width: 1440px;
  min-height: 100vh;
  margin: 0 auto;

  .about {
    width: 100%;
    min-width: 1440px;
    height: 889px;
    box-sizing: border-box;
    padding: 120px 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(36, 41, 51, 1);
    background-image: url("https://cdns.iamberry.com/metapure/static/images/aboutBg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .title {
      font-size: 32px;
      color: #FFFFFF;
      line-height: 50px;
    }

    .titles {
      color: #717171;
      line-height: 24px;
      font-size: 18px;
      margin-top: 20px;
      margin-bottom: 78px;
    }

    .about_content {
      width: 1080px;
      min-height: 400px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .about_left {
        width: 477px;
        height: 477px;
      }

      .hr {
        width: 1px;
        height: 400px;
        background: #5B5B5B;
      }

      .about_right {
        width: 431px;
        min-height: 477px;
        display: flex;
        flex-direction: column;

        .color3 {
          color: rgba(203, 203, 203, 1);
          font-size: 16px;
          line-height: 25px;
        }

        .color1 {
          color: rgba(203, 203, 203, 1);
          font-size: 16px;
          line-height: 25px;
          margin-top: 16px;
          margin-bottom: 36px;
        }

        .hrs {
          width: 36px;
          height: 4px;
          background: #FFFFFF;
          margin-top: 50px;
          margin-bottom: 40px;
        }

        .color2 {
          color: #FFFFFF !important;
          font-size: 18px;
          line-height: 29px;
        }
      }
    }
  }

  .popular_product {
    width: 100%;
    min-width: 1440px;
    min-height: 800px;
    padding-top: 68px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(36, 41, 51, 1);
    padding-bottom: 68px;

    .more {
      text-align: center;
      width: 158px;
      height: 50px;
      background: #FFFFFF;
      border-radius: 2px;
      font-size: 16px;
      color: rgba(36, 41, 51, 1);
      line-height: 50px;
      margin-top: 80px;
      cursor: pointer;
    }

    .title {
      font-size: 32px;
      color: #FFFFFF;
      line-height: 50px;
    }

    .titles {
      color: #717171;
      line-height: 24px;
      font-size: 18px;
      margin-top: 20px;
      margin-bottom: 78px;
    }

    .product {
      width: 1440px;
      height: 520px;
      overflow: hidden;
    }
  }

  .productType {
    box-sizing: border-box;
    width: 100%;
    min-width: 1440px;
    height: 824px;
    background: rgba(30, 31, 41, 0.82);
    background-image: url("https://cdns.iamberry.com/metapure/static/images/productBg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 120px 180px;

    .title {
      font-size: 32px;
      color: #FFFFFF;
      line-height: 50px;
    }

    .titles {
      color: #717171;
      line-height: 24px;
      font-size: 18px;
      margin-top: 20px;
      margin-bottom: 78px;
    }

    .product {
      width: 1080px;
      height: 412px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .product_detail {
        width: 334px;
        height: 412px;
        background: rgba(255, 255, 255, 0.08);
        border-radius: 10px;
        backdrop-filter: blur(42px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .img {
          width: 164px;
          height: 164px;
        }

        .product_name {
          margin-top: 59px;
          width: 158px;
          height: 50px;
          background: #FFFFFF;
          border-radius: 2px;
          text-align: center;
          color: #242933;
          font-size: 16px;
          line-height: 50px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
