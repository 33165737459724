const productList = [
    {
        name: 'H矿化系列', children: [
            { name: 'H矿化系列 - 岩泉 Healthy7', colorName: '深空灰', color: 'rgba(113, 113, 113, 1)', imgsrc: 'metapure/static/pcImg/p-h7-g.png', id: '2' },
            { name: 'H矿化系列 - 岩泉 Healthy7', colorName: '净息白', color: 'rgba(243, 243, 243, 1)', imgsrc: 'metapure/static/pcImg/p-h7-w.png', id: '1' },
        ]
    },
    {
        name: 'R复古系列', children: [
            { name: 'Retro 3', colorName: '玛瑙蓝', color: '#C8DBE0', imgsrc: 'metapure/static/pcImg/p-r3-b.png', id: '3' },
            { name: 'Retro 3', colorName: '陨石灰', color: '#7C7C7D', imgsrc: 'metapure/static/pcImg/p-r3-g.png', id: '5' },
            { name: 'Retro 3', colorName: '奶油白', color: '#F7F0E6', imgsrc: 'metapure/static/pcImg/p-r3-w.png', id: '4' },
        ]
    },
    {
        name: 'C时尚系列', children: [
            { name: 'Convenient 1', colorName: '云水蓝', color: '#E1E7EB', imgsrc: 'metapure/static/pcImg/p-c1-g.png', id: '6' },
        ]
    },
    {
        name: '富氢系列', children: [
            { name: 'WA-1', colorName: '青蓝', color: '#00C1DB', imgsrc: 'metapure/static/pcImg/p-WA1_b.png', id: '7' },
            { name: 'Modern 5', colorName: '雾海蓝', color: '#5C768F', imgsrc: 'metapure/static/pcImg/p-M5-b.png', id: '8' },
        ]
    },
    {
        name: '健康爱护系列', children: [
            { name: '挂耳咖啡机', colorName: '', color: '', imgsrc: 'metapure/static/pcImg/p-J5-kafei.png', id: '9' },
            { name: '软水机', colorName: '', color: '', imgsrc: 'metapure/static/pcImg/p-J5-ruan.png', id: '10' },
            { name: '前置过滤器', colorName: '', color: '', imgsrc: 'metapure/static/pcImg/p-J5-guolv.png', id: '11' },
            { name: '花洒-N02', colorName: '', color: '', imgsrc: 'metapure/static/pcImg/p-J5-hua.png', id: '12' },
            { name: '花洒-N03', colorName: '', color: '', imgsrc: 'metapure/static/pcImg/p-J5-huaNO3.png', id: '13' }
        ]
    },
]
const mobileProduct = [
    {
        name: 'H矿化系列', children: [
            { name: '岩泉 Healthy7', color: '#717171', colorName: '深空灰', imgsrc: 'metapure/static/mobile/navTop/Hxilie1.png', id: '2' },
            { name: '岩泉 Healthy7', color: '#F3F3F3', colorName: '净息白', imgsrc: 'metapure/static/mobile/navTop/Hxilie2.png', id: '1' }
        ]
    },
    {
        name: 'R复古系列', children: [
            { name: 'Retro 3', colorName: '玛瑙蓝', color: '#C8DBE0', imgsrc: 'metapure/static/mobile/navTop/Rxilie1.png', id: '3' },
            { name: 'Retro 3', colorName: '奶油白', color: '#F7F0E6', imgsrc: 'metapure/static/mobile/navTop/Rxilie2.png', id: '4' },
            { name: 'Retro 3', colorName: '陨石灰', color: 'rgba(124, 124, 125, 1)', imgsrc: 'metapure/static/mobile/navTop/Rxilie3.png', id: '5' },
        ]
    },
    {
        name: 'C时尚系列', children: [
            { name: 'Convenient 1', colorName: '云水蓝', color: '#E1E7EB', imgsrc: 'metapure/static/mobile/navTop/Cxilie1.png', id: '6' },
        ]
    },
    {
        name: '富氢系列', children: [
            { name: 'WA-1', colorName: '青蓝', color: '#00C1DB', imgsrc: 'metapure/static/mobile/navTop/FQxilie1.png', id: '7' },
            { name: 'Modern 5', colorName: '雾海蓝', color: '#5C768F', imgsrc: 'metapure/static/mobile/navTop/FQxilie2.png', id: '8' },
        ]
    },
    {
        name: '健康爱护系列', children: [
            { name: '挂耳咖啡机-A1', imgsrc: 'metapure/static/mobile/navTop/JKxilie1.png', id: '9' },
            { name: '软水机-N02', imgsrc: 'metapure/static/mobile/navTop/JKxilie2.png', id: '10' },
            { name: '花洒-N03', imgsrc: 'metapure/static/mobile/navTop/JKxilie3.png', id: '13' },
            { name: '花洒-N02', imgsrc: 'metapure/static/mobile/navTop/JKxilie4.png', id: '12' },
            { name: '前置过滤器 FC1', imgsrc: 'metapure/static/mobile/navTop/JKxilie5.png', id: '11' }
        ]
    },
]
const h7baisrc = 'metapure/static/productPcimg/h7bai/' //  H矿化系列岩泉 Healthy7 净息白
const h7huisrc = 'metapure/static/productPcimg/h7hui/' //  H矿化系列岩泉 Healthy7 深空灰
const Rmalaosrc = 'metapure/static/productPcimg/R3malao/' //  Retro 3 玛瑙蓝
const Rnaiyousrc = 'metapure/static/productPcimg/R3naiyou/' //  Retro 3 奶油白
const Ryunshisrc = 'metapure/static/productPcimg/R3yunshi/' //  Retro 3 陨石灰
const C1src = 'metapure/static/productPcimg/c1/' // Convenient 
const fuqinwasrc = 'metapure/static/productPcimg/WA-1/' //  WA-1
const fuqinmo5src = 'metapure/static/productPcimg/m5water/' // Modern 5
const guaersrc = 'metapure/static/productPcimg/guaer/' // 挂耳咖啡机
const qianzhisrc = 'metapure/static/productPcimg/qianzhiguolv/' // 前置过滤器
const N02src = 'metapure/static/productPcimg/N02/' // 花洒N0-2
const N03src = 'metapure/static/productPcimg/N03/' // 花洒N0-3
const Meirongsrc = 'metapure/static/productPcimg/meirong/' // 软水机 
const productListDetail = [
    {
        id: '1',
        name: 'H矿化系列岩泉 Healthy7',
        note: '富锶天然矿化 | RO多重过滤 | 即热即饮',
        banner: [`${h7baisrc}s1.jpg`, `${h7baisrc}s2.jpg`, `${h7baisrc}s3.jpg`, `${h7baisrc}s4.jpg`, `${h7baisrc}s5.jpg`,],
        detail: h7baisrc + 'detail.jpg',
        jieshao: h7baisrc + 'jieshao.png',
        texing: h7baisrc + 'texing.png',
        canshu: h7baisrc + 'canshu.png',
        purchaseLink: 'https://detail.tmall.com/item.htm?abbucket=4&id=683936397535&ns=1&spm=a230r.1.14.11.225e7e36wHjlFb&skuId=5183955787095',
        curIndex: 0
    },
    {
        id: '2',
        name: 'H矿化系列岩泉 Healthy7',
        note: '富锶天然矿化 | RO多重过滤 | 即热即饮',
        banner: [`${h7huisrc}1.jpg`, `${h7huisrc}2.jpg`, `${h7huisrc}3.jpg`, `${h7huisrc}4.jpg`, `${h7huisrc}5.jpg`],
        detail: `${h7huisrc}detail.jpg`,
        jieshao: `${h7huisrc}jieshao.png`,
        texing: `${h7huisrc}texing.png`,
        canshu: `${h7huisrc}canshu.png`,
        purchaseLink: 'https://detail.tmall.com/item.htm?abbucket=4&id=683936397535&ns=1&skuId=4892374981638&spm=a230r.1.14.11.225e7e36wHjlFb',
        curIndex: 0
    },
    {
        id: '3',
        name: 'Aiberle Retro 3',
        note: '时尚复古 | RO多重过滤 | 即热即饮',
        banner: [`${Rmalaosrc}1.png`, `${Rmalaosrc}2.png`, `${Rmalaosrc}3.png`],
        detail: `${Rmalaosrc}detail.jpg`,
        jieshao: `${Rmalaosrc}jieshao.png`,
        texing: `${Rmalaosrc}texing.png`,
        canshu: `${Rmalaosrc}canshu.png`,
        purchaseLink: 'https://detail.tmall.com/item.htm?abbucket=3&id=594646076885&rn=e8be7d864ff27e4a3e4e2ea39bfc4fc8&spm=a1z10.5-b-s.w4011-21697804125.45.3f2c1ba1JrhJ8q',
        curIndex: 1
    },
    {
        id: '4',
        name: 'Aiberle Retro 3',
        note: '时尚复古 | RO多重过滤 | 即热即饮',
        banner: [`${Rnaiyousrc}1.jpg`, `${Rnaiyousrc}2.jpg`, `${Rnaiyousrc}3.jpg`, `${Rnaiyousrc}4.jpg`, `${Rnaiyousrc}5.jpg`, `${Rnaiyousrc}6.jpg`],
        detail: `${Rnaiyousrc}detail.jpg`,
        jieshao: `${Rnaiyousrc}jieshao.png`,
        texing: `${Rnaiyousrc}texing.png`,
        canshu: `${Rnaiyousrc}canshu.png`,
        purchaseLink: 'https://detail.tmall.com/item.htm?abbucket=3&id=594646076885&rn=e8be7d864ff27e4a3e4e2ea39bfc4fc8&spm=a1z10.5-b-s.w4011-21697804125.45.3f2c1ba1JrhJ8q&skuId=5012227265569',
        curIndex: 1
    },
    {
        id: '5',
        name: 'Aiberle Retro 3',
        note: '时尚复古 | RO多重过滤 | 即热即饮',
        banner: [`${Ryunshisrc}1.jpg`, `${Ryunshisrc}2.jpg`, `${Ryunshisrc}3.jpg`, `${Ryunshisrc}4.jpg`, `${Ryunshisrc}5.jpg`],
        detail: `${Ryunshisrc}detail.jpg`,
        jieshao: `${Ryunshisrc}jieshao.png`,
        texing: `${Ryunshisrc}texing.png`,
        canshu: `${Ryunshisrc}canshu.png`,
        purchaseLink: 'https://detail.tmall.com/item.htm?abbucket=3&id=594646076885&rn=e8be7d864ff27e4a3e4e2ea39bfc4fc8&skuId=4886466516945&spm=a1z10.5-b-s.w4011-21697804125.45.3f2c1ba1JrhJ8q',
        curIndex: 1
    },
    {
        id: '6',
        name: 'Aiberle C1',
        note: '可拆卸纯水箱 | 语音提示',
        banner: [`${C1src}01.jpg`, `${C1src}02.jpg`, `${C1src}03.jpg`, `${C1src}04.jpg`, `${C1src}05.jpg`, `${C1src}06.jpg`],
        detail: `${C1src}detail.jpg`,
        jieshao: `${C1src}jieshao.png`,
        texing: `${C1src}texing.png`,
        canshu: `${C1src}canshu.png`,
        purchaseLink: 'https://detail.tmall.com/item.htm?abbucket=3&id=640305776531&rn=8d4242e04c48a69df1f946300e5e469a&scene=taobao_shop&spm=a1z10.1-b-s.w5003-23864175772.1.794376d4X4CccH&skuId=4601417912996',
        curIndex: 2
    },
    {
        id: '7',
        name: 'WA-1 富氢机',
        note: '富氢水 | 即饮净水 | 健康',
        banner: [`${fuqinwasrc}1.jpg`, `${fuqinwasrc}2.jpg`, `${fuqinwasrc}3.jpg`, `${fuqinwasrc}4.jpg`],
        detail: `${fuqinwasrc}detail.jpg`,
        jieshao: `${fuqinwasrc}jieshao.png`,
        texing: `${fuqinwasrc}texing.png`,
        canshu: `${fuqinwasrc}canshu.png`,
        purchaseLink: 'https://detail.tmall.com/item.htm?abbucket=3&id=594744614692&rn=ca1d7bac0921e6a07dbb6d2cca8b5196&spm=a1z10.5-b-s.w4011-21697804125.55.72d71ba1hwBtEv&skuId=4120543881081',
        curIndex: 3
    },
    {
        id: '8',
        name: 'Modern 5 富氢机',
        note: '富氢水 | 即饮净水 | 健康',
        banner: [`${fuqinmo5src}1.jpg`, `${fuqinmo5src}2.jpg`, `${fuqinmo5src}3.jpg`, `${fuqinmo5src}4.jpg`],
        detail: `${fuqinmo5src}detail.jpg`,
        jieshao: `${fuqinmo5src}jieshao.png`,
        texing: `${fuqinmo5src}texing.png`,
        canshu: `${fuqinmo5src}canshu.png`,
        purchaseLink: 'https://detail.tmall.com/item.htm?abbucket=11&id=681368494522&rn=c5c0e77df8167c1e3f60e1fc83c7ac31&spm=a1z10.3-b-s.w4011-21697804128.53.22ba502dTj6s4I&skuId=5007909084556',
        curIndex: 3
    },
    {
        id: '9',
        name: '咖啡机',
        note: '大师级自动冲泡 | 三孔出水 | 智能电控',
        banner: [`${guaersrc}1.jpg`, `${guaersrc}2.jpg`, `${guaersrc}3.jpg`, `${guaersrc}4.jpg`, `${guaersrc}5.jpg`],
        detail: `${guaersrc}detail.jpg`,
        jieshao: `${guaersrc}jieshao.png`,
        texing: `${guaersrc}texing.png`,
        canshu: `${guaersrc}canshu.png`,
        purchaseLink: 'https://detail.tmall.com/item.htm?abbucket=17&id=681498920221&rn=15bbafb9f2be57f3207e4573c6bd259a&skuId=5057744875383&spm=a1z10.3-b-s.w4011-21697804128.108.79de502dKjOUr4',
        curIndex: 4
    },
    {
        id: '10',
        name: '软水机',
        note: '软化水质 | 优质超滤',
        banner: [`${Meirongsrc}1.jpg`, `${Meirongsrc}2.jpg`, `${Meirongsrc}3.jpg`, `${Meirongsrc}4.jpg`],
        detail: `${Meirongsrc}detail.jpg`,
        jieshao: `${Meirongsrc}jieshao.png`,
        texing: `${Meirongsrc}texing.png`,
        canshu: `${Meirongsrc}canshu.png`,
        purchaseLink: 'https://detail.tmall.com/item.htm?abbucket=3&id=594819329861&rn=ba3a3bc97ecb9c764bac4ecad88825a1&spm=a1z10.3-b-s.w4011-21697804128.91.5a25502dixbUqf&skuId=4291361054954',
        curIndex: 4
    },
    {
        id: '11',
        name: '前置过滤器',
        note: '6T大流量 | 双涡轮增压 | 隔铅水路',
        banner: [`${qianzhisrc}01.jpg`, `${qianzhisrc}02.jpg`, `${qianzhisrc}03.jpg`, `${qianzhisrc}04.jpg`, `${qianzhisrc}05.jpg`],
        detail: `${qianzhisrc}detail.jpg`,
        jieshao: `${qianzhisrc}jieshao.png`,
        texing: `${qianzhisrc}texing.png`,
        canshu: `${qianzhisrc}canshu.png`,
        purchaseLink: 'https://detail.tmall.com/item.htm?abbucket=3&id=674474177428&rn=13b2fc17fdae1ab4c04ed5fd2f32d8bb&spm=a1z10.3-b-s.w4011-21697804128.99.6558502dxE0OcE',
        curIndex: 4
    },
    {
        id: '12',
        name: 'N02花洒',
        note: '高效除氯 | 净水养肤 | 抑菌滤芯',
        banner: [`${N02src}1.jpg`, `${N02src}2.jpg`, `${N02src}3.jpg`, `${N02src}4.jpg`],
        detail: `${N02src}detail.jpg`,
        jieshao: `${N02src}jieshao.png`,
        texing: `${N02src}texing.png`,
        canshu: `${N02src}canshu.png`,
        purchaseLink: 'https://detail.tmall.com/item.htm?abbucket=3&id=602345019963&rn=ba3a3bc97ecb9c764bac4ecad88825a1&spm=a1z10.3-b-s.w4011-21697804128.81.5a25502dixbUqf&skuId=4490740182060',
        curIndex: 4
    },
    {
        id: '13',
        name: 'N03花洒',
        note: '复古美肤 | 增压节水 | 肌肤保湿',
        banner: [`${N03src}1.jpg`, `${N03src}2.jpg`, `${N03src}3.jpg`, `${N03src}4.jpg`, `${N03src}5.jpg`],
        detail: `${N03src}detail.jpg`,
        jieshao: `${N03src}jieshao.png`,
        texing: `${N03src}texing.png`,
        canshu: `${N03src}canshu.png`,
        purchaseLink: 'https://detail.tmall.com/item.htm?abbucket=3&id=626094546843&rn=ba3a3bc97ecb9c764bac4ecad88825a1&spm=a1z10.3-b-s.w4011-21697804128.76.5a25502dixbUqf',
        curIndex: 4
    },
]
const productxilie = [{key:0, name: 'H矿化系列'},{key: 1, name: 'R复古系列'},{key: 2, name: 'C时尚系列'},{key: 3, name: '富氢系列'},{key: 4, name: '健康系列'}]
export default {
    productList,
    mobileProduct,
    productListDetail,
    productxilie
}