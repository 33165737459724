<template>
  <div class="product">
    <navTop :index="1"></navTop>
    <div class="bst">
      <img :src="splitImageHttp('metapure/static/pcImg/procenter_banner1.jpg')" alt="">
    </div>
    <div class="productmenu" id="productmenu">
      <div class="menuList">
        <span class="title">产品中心</span>
        <div class="list">
          <span @click="changes(index)" :class="{ 'chose': index == proIndex }" v-for="(item, index) in productList"
            :key="index">{{ item.name }}</span>
        </div>
      </div>
      <div class="product_detail">
        <div class="detail" v-for="(item, index) in productList[proIndex].children" :key="index"
          @click="transform(item.id, productList[proIndex].name, item.name)">
          <div class="proImg">
            <img :src="splitImageHttp(item.imgsrc)" alt="">
          </div>
          <span class="proName">{{ item.name }}</span>
          <div class="projs">
            <p :style="{ 'background': item.color }"></p><span>{{ item.colorName }}</span>
          </div>
        </div>
      </div>
    </div>
    <navbt></navbt>
  </div>
</template>
<script>
import navTop from "@/components/NavTop";
import navbt from "@/components/navBt";
import jsonData from "@/commom/mock.js";

export default {
  components: {
    navTop,
    navbt,
  },
  created() {
    this.productList = jsonData.productList
    this.proIndex = this.$route.query.index || 0


  },
  data() {
    return {
      productList: [],
      proIndex: 0,
    };
  },
  mounted() {
    const porCenter = document.querySelector(".productmenu");
    const indexs = this.$route.query.index;
    // console.log(indexs);
    if (indexs) {
      this.$nextTick(() =>
        porCenter.scrollIntoView()
      )
    }
  },
  methods: {
    changes(key) {
      this.proIndex = key
    },
    transform(id, xilie, name) {
      if (this.$route.path === '/home/introduce') return
      this.$router.push({ path: '/home/introduce', query: { id: id, series: xilie, name: name } })
    }
  },
};
</script> 
<style lang="less" scoped>
.product {
  width: 100%;
  min-width: 1440px;
  min-height: 100vh;
  margin: 0 auto;

  .bst {
    width: 100%;
    min-width: 1440px;
    height: 510px;
    display: flex;
    justify-content: center;
    overflow: hidden;
  }

  .productmenu {
    width: 1440px;
    min-height: 755px;
    box-sizing: border-box;
    padding: 80px 0px 90px 180px;
    margin: 0 auto;

    .product_detail {
      width: 1260px;
      min-height: 441px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 100px;
    }

    // .detail:hover {
    //   box-shadow: 0px 7px 13px 0px rgba(0, 0, 0, 0.04);
    //   border-radius: 10px;
    //   border: none;
    // }
    .detail:hover .proImg img {
      transform: scale(1.05);
    }

    .detail {
      width: 340px;
      height: 441px;
      background: #FFFFFF;
      border-radius: 10px;
      border: 1px solid #E6E6E6;
      margin-right: 31px;
      margin-bottom: 31px;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;

      .proImg {
        width: 340px;
        height: 342px;
        background: #F5F5F5;
        border-radius: 10px 10px 0px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        /*img{
                width: 280px;
                height: 280px;
            }*/
      }

      .proName {
        color: rgba(36, 41, 51, 1);
        font-size: 16px;
        line-height: 25px;
        margin-top: 21px;
        margin-bottom: 12px;
      }

      .projs {
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgba(36, 41, 51, 1);
        font-size: 14px;
        line-height: 18px;

        p {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          margin-right: 12px;
        }
      }
    }

    .menuList {
      display: flex;
      align-items: center;

      .title {
        color: rgba(36, 41, 51, 1);
        font-size: 28px;
        line-height: 44px;
      }

      .list {
        width: 589px;
        min-height: 21px;
        margin-left: 370px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        .chose {
          color: rgba(105, 179, 231, 1) !important;
        }

        span {
          color: rgba(36, 41, 51, 1);
          font-size: 16px;
          line-height: 21px;
        }
      }
    }
  }
}
</style>