<template>
    <div class="mbanner">
        <swiper ref="mySwiper" class="swiper" :options="options">
            <swiper-slide class="swiperct" v-for="(item, index) in banners" :key="index">
                <img :src="splitImageHttp(item.src)" :data-path="item.id" alt="">
                <div class="text" :data-path="item.id">
                    <span class="proName" :data-path="item.id"  :style="{color:item.color}">{{ item.name }}</span>
                    <span class="proNote" :data-path="item.id" :style="{color:item.color}">{{ item.note }}</span>
                    <span class="proMore" :data-path="item.id" :style="{color:item.color}">了解产品 <img
                            :src="splitImageHttp(item.right==='s1'?'metapure/static/mobile/home/icon_b.png':'metapure/static/mobile/home/icon_w.png')" :data-path="item.id" alt="" ></span>
                </div>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
    </div>
</template>
<script>
let vm = null
export default {
    data() {
        return {
            banners: [
                { color: '#242933',name: 'Aiberle C1', src: 'metapure/static/mobile/home/banner1.png', note: '可拆卸纯水箱 | 语音提示', icon: 'metapure/static/mobile/home/icon_b.png', id: '6', series: 'C时尚系列' , right: 's1' },
                { color: '#242933',name: 'Aiberle Retro 3', src: 'metapure/static/mobile/home/banner2.png', note: '时尚复古 | RO多重过滤 | 即热即饮', icon: 'metapure/static/mobile/home/icon_b.png', id: '3', series: 'R复古系列' , right: 's1' },
                { color: '#242933',name: '岩泉 Healthy 7', src: 'metapure/static/mobile/home/banner3.png', note: '富锶天然矿泉 | RO多重过滤 | 即热即饮', icon: 'metapure/static/mobile/home/icon_b.png', id: '2', series: 'H矿化系列', right: 's1' },
                { color: '#FFFFFF', name: 'Modern 5 富氢机', src: 'metapure/static/mobile/home/banner4.png', note: '富氢水 | 即饮净水 | 健康', icon: 'metapure/static/mobile/home/icon_b.png', id: '8', series: '富氢系列', right: 's2'  }
            ],
            options: {
                loop: true,
                initialSlide: 0,
                autoplay: true,
                autoplay: {
                  //swiper手动滑动之后自动轮播失效的解决方法,包括触碰，拖动，点击pagination,重新启动自动播放
                  disableOnInteraction: false,
                  // 自动播放时间：毫秒
                  delay: 2000,
                },
                freeMode: false,
                on: {
                    click: function(e){
                      if(!e.target.dataset.path) return
                      vm.productDetail(e.target.dataset.path)
                    },
                },
                pagination: {
                    el: ".swiper-pagination",
                    // clickable: true,
                    bulletClass: "my-bullet",
                    bulletActiveClass: 'my-bullet-active',
                },
            },

        };
    },  
    created(){
       vm = this
    },
    methods: {
        //轮播图跳转至详情页
        productDetail(id) { this.$router.push({ path: '/mobile/introduce', query: { id: id} }) },
    }
}
</script>
<style lang="less" scoped>
.mbanner {
    width: 100%;
    min-height: 987rem;
    overflow: hidden;

    .swiperct {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;

        img {
            width: 750rem;
            height: 987rem;
        }

        .text {
            position: absolute;
            top: 110rem;
            left: 0;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .proName {
                font-size: 48rem;
                font-weight: 600;
                line-height: 76rem;
            }

            .proNote {
                font-size: 36rem;
                font-weight: normal;
                line-height: 47rem;
                margin-top: 16rem;
                margin-bottom: 48rem;
            }

            .proMore {
                display: flex;
                align-items: center;
                font-size: 28rem;
                font-weight: 600;
                line-height: 37rem;

                img {
                    width: 32rem;
                    height: 22rem;
                    margin-left: 15rem;
                }
            }

        }
    }
}
</style>
<style lang="less">
.my-bullet {
    display: inline-block;
    width: 12rem;
    height: 12rem;
    border-radius: 100%;
    background: #fff !important;
    opacity: 0.48 !important;
    margin: 0 18rem;
}

.my-bullet-active {
    background: #fff !important;
    opacity: 1 !important;
}
</style>