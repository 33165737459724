<template>
    <div class="about">
        <navTop :index="2"></navTop>
        <div class="culture_box">
            <div class="cultures">
                <div class="culture">
                    <div class="title">关于<br />Aiberle爱贝源</div>
                    <div class="line"></div>
                    <div class="text">以创新智造为驱，以关爱呵护为本<br />守护家庭用水健康，创造美好生活。</div>
                </div>
                <div class="model"><img :src="splitImageHttp('metapure/static/pcImg/about_mote.png')" alt=""></div>
                <div class="content">
                    <div class="content_left">
                        <div>
                            <div class="title1">企业使命</div>
                            <div class="text1">守护生命之源，呵护家庭用水健康。</div>
                        </div>
                        <div>
                            <div class="title1">核心价值观</div>
                            <div class="text1">关爱、用户导向、创新设计、责任<br />感、同理心、诚信、合作。</div>
                        </div>
                    </div>
                    <div class="content_line"></div>
                    <div class="content_right">
                        <div class="title1">愿景</div>
                        <div class="text1">用科技创新、智能制造，将关爱融入<br />产品，展现科技之美、呵护亿万家庭<br />用水健康，创造美好生活。</div>
                    </div>
                </div>

            </div>
        </div>
        <div class="develop">
            <div class="title">品牌发展历程</div>
            <div class="course">
                <div class="course_left">
                    <div class="list">
                        <div class="year">2007</div>
                        <div class="event">
                            2007-Aiberle成立，致力于用科技改变生活，在生活安全<br />健康领域打造卓越的产品。同期，在美国成立研发中心，<br />先进技术指导中国区生产，中国制造，赶超国际标准。
                        </div>
                        <img src="https://cdns.iamberry.com/metapure/static/pcImg/us-year1.png" alt="">
                    </div>
                    <div class="list">
                        <div class="year">2015</div>
                        <div class="event">
                            关注母婴群体，关注宝宝饮水安全与冲奶健康。
                        </div>
                        <img src="https://cdns.iamberry.com/metapure/static/pcImg/us-year3.png" alt="">
                    </div>
                </div>
                <div class="course_line">
                    <div>
                        <div class="circle">
                            <div class="point"></div>
                        </div>
                        <div class="line"></div>
                    </div>
                    <div>
                        <div class="circle">
                            <div class="point"></div>
                        </div>
                        <div class="line"></div>
                    </div>
                    <div>
                        <div class="circle">
                            <div class="point"></div>
                        </div>
                        <div class="line"></div>
                    </div>
                    <div>
                        <div class="circle">
                            <div class="point"></div>
                        </div>
                        <div class="line"></div>
                    </div>
                    <div>
                        <div class="circle">
                            <div class="point"></div>
                        </div>
                        <div class="line"></div>
                    </div>

                </div>
                <div class="course_right">
                    <div class="list">
                        <div class="year">2014</div>
                        <div class="event">
                            Aiberle爱贝源成立全球营销总部——深圳爱贝源科技有限公司。
                        </div>
                        <img src="https://cdns.iamberry.com/metapure/static/pcImg/us-year2.png" alt="">
                    </div>
                    <div class="list">
                        <div class="year">2016</div>
                        <div class="event">
                            Aiberle爱贝源针对母婴用户，研发了人性化的专业冲奶<br />机，开创了品类先河。
                        </div>
                        <img src="https://cdns.iamberry.com/metapure/static/pcImg/us-year4.png" alt="">
                    </div>
                </div>
            </div>
            <div class="course two" v-show="clicking">
                <div class="course_left">
                    <div class="list">
                        <div class="year">2017</div>
                        <div class="event">
                            爱贝源桌面净饮机正式上市，以行业最高标准，驱动品牌持续创新。
                        </div>
                        <img src="https://cdns.iamberry.com/metapure/static/pcImg/year2017_1.png"
                            alt="">
                    </div>
                    <div class="list">
                        <div class="year">2018</div>
                        <div class="event">
                            品牌团队重组，结合市场发展情况，大力转型并开启了新的赛道。
                        </div>
                        <img src="https://cdns.iamberry.com/metapure/static/pcImg/year2018.png" alt="">
                    </div>
                    <div class="list">
                        <div class="year">2020</div>
                        <div class="event">
                            主流电商全面发力，爱贝源在净饮机品类里面占领一席之地。
                        </div>
                        <img src="https://cdns.iamberry.com/metapure/static/pcImg/year2020.png" alt="">
                    </div>

                </div>
                <div class="course_line">
                    <div>
                        <div class="circle">
                            <div class="point"></div>
                        </div>
                        <div class="line"></div>
                    </div>
                    <div>
                        <div class="circle">
                            <div class="point"></div>
                        </div>
                        <div class="line" style="height:311px"></div>
                    </div>
                    <div>
                        <div class="circle">
                            <div class="point"></div>
                        </div>
                        <div class="line" style="height:166px"></div>
                    </div>
                    <div>
                        <div class="circle">
                            <div class="point"></div>
                        </div>
                        <div class="line" style="height:247px"></div>
                    </div>
                    <div>
                        <div class="circle">
                            <div class="point"></div>
                        </div>
                        <div class="line" style="height:134px"></div>
                    </div>
                    <div>
                        <div class="circle">
                            <div class="point"></div>
                        </div>
                        <div class="line" style="height:380px"></div>
                    </div>
                    <div>
                        <div class="circle">
                            <div class="point"></div>
                        </div>
                        <!-- <div class="line"></div> -->
                    </div>
                </div>
                <div class="course_right">
                    <div class="list">
                        <div class="year">2017</div>
                        <div class="event">
                            Aiberle爱贝源历经层层筛选被联合国教科文组织授予官方合作品牌，荣登国际舞台，不仅彰显了其作为高端生活家电的卓绝品牌实力，更体现了国际组织对于爱贝源品质的充分信赖。
                        </div>
                        <img src="https://cdns.iamberry.com/metapure/static/pcImg/year2017_2.png"
                            alt="">
                    </div>
                    <div class="list">
                        <div class="year">2019</div>
                        <div class="event">
                            全面开启主流电商+新媒体渠道二合一的发展模式。
                        </div>
                        <img src="https://cdns.iamberry.com/metapure/static/pcImg/year2019.png" alt="">
                    </div>
                    <div class="list">
                        <div class="year">2021</div>
                        <div class="event">
                            爱贝源全力占据品类位置，产品线成熟并走上了快速发展之路。
                        </div>
                        <img src="https://cdns.iamberry.com/metapure/static/pcImg/year2021.png" alt="">
                    </div>
                </div>
            </div>
            <div class="more" @click="getClick">
                {{ clicking === false?'查看更多':'收起' }}
                <img :src="splitImageHttp(clicking === false ? 'metapure/static/pcImg/us-moreOpen.png' : 'metapure/static/pcImg/us-moreClose.png')"
                    alt="">
            </div>
        </div>
        <div class="map_box">
            <div class="map">
                <div class="title">以人为本 研发引领</div>
                <div class="text">
                    <span class="item">
                        <div class="number">30<sup>+</sup></div>
                        <div class="attr">研发技术人员</div>
                    </span>
                    <span class="item">
                        <div class="number">15<sup>+</sup></div>
                        <div class="attr">出口国家和地区</div>
                    </span>
                    <span class="item">
                        <div class="number">30<sup><span>万</span>+</sup></div>
                        <div class="attr">全球用户覆盖</div>
                    </span>
                </div>
                <div class="pdcenter">
                    <div class="title">智能制造中心</div>
                    <div class="dongguan">
                        <div class="place">中国东莞</div>
                        <div class="line"></div>
                        <div class="circle">
                            <div class="point"></div>
                        </div>
                    </div>
                    <div class="anshan">
                        <div class="place">中国马鞍山</div>
                        <div class="line"></div>
                        <div class="circle">
                            <div class="point"></div>
                        </div>
                    </div>
                    <div class="shenzhen">
                        <div class="circle">
                            <div class="point"></div>
                        </div>
                        <div class="line"></div>
                        <div class="place">中国深圳</div>
                    </div>
                </div>
            </div>
            <div class="map_img">
                <img :src="splitImageHttp('metapure/static/pcImg/us-map3.jpg')" alt="">
            </div>
        </div>
        <navbt></navbt>
    </div>
</template> 
<script>
import navTop from '@/components/NavTop'
import navbt from '@/components/navBt'

export default {
    name: "About",
    components: {
        navTop,
        navbt
    },
    created() {
    },
    data() {
        return {
            clicking: false
        }
    },
    methods: {
        getClick: function () {
            return this.clicking = !this.clicking
        }
    }
}

</script>
<style lang="less" scoped>
.about {
    width: 100%;
    min-width: 1440px;
    min-height: 100vh;
    box-sizing: border-box;
    background: #F5F7FA;

}

.culture_box {
    width: 100%;
    min-width: 1440px;
    height: 800px;
    background: #F5F7FA url(https://cdns.iamberry.com/metapure/static/pcImg/about_motebg.png) no-repeat center;
    background-size: cover;
}

.cultures {
    position: relative;
    width: 1440px;
    height: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: space-between;
}

.model {
    margin-top: 134px;
}

.culture>.title {
    color: #242933;
    font-size: 48px;
    font-weight: 700;
    line-height: 76px;
    padding: 120px 0 0 180px;
}


.culture>.line {
    width: 73px;
    height: 4px;
    background: #242933;
    margin: 87px 0 0 180px;
}


.culture>.text {
    font-size: 24px;
    color: #242933;
    line-height: 32px;
    margin: 26px 0 0 180px;
}

.content {
    display: flex;
    position: absolute;
    top: 643px;
    left: 180px;
    width: 1080px;
    height: 438px;
    background: #FFFFFF;
    box-shadow: 0px 10px 26px 0px rgba(0, 0, 0, 0.02);
    border-radius: 20px;
    margin: 0 auto;
    z-index: 99;

}

.content .content_left {
    margin: 84px 0 0 126px;
}

.content .title1 {
    font-size: 24px;
    font-weight: 700;
    color: #242933;
    line-height: 38px;
}

.content .text1 {
    font-size: 20px;
    color: #5B5F68;
    margin-top: 12px;
}

.content .content_left>div:nth-child(2) {
    margin-top: 50px;
}

.content_line {
    width: 1px;
    height: 298px;
    background: #E6E6E6;
    margin: 70px 90px 0 88px;
}

.content .content_right {
    margin: 84px 0 0 0;
}

.develop {
    width: 1440px;
    background-color: #F5F7FA;
    padding-top: 401px;
    margin: 0 auto;

    .title {
        font-size: 32px;
        font-weight: 700;
        color: #242933;
        line-height: 50px;
        text-align: center;
    }

    .course {
        display: flex;
        margin: 80px 179px 0 180px;

    }
}

.course .year {
    font-size: 24px;
    color: #242933;
    line-height: 18px;
    font-weight: normal;
}

.course .event {
    font-size: 18px;
    color: #242933;
    line-height: 32px;
    padding: 20px 0;
}

.course img {
    width: 459px;
    height: 259px;
}

.course .list {
    box-sizing: border-box;
    margin-top: 80px;
}

.course .course_left>.list:nth-child(1) {
    margin-top: 1px;
}

.course_line {
    margin: 0 72px 0 72px;
}

.course_line .circle {
    width: 20px;
    height: 20px;
    border: 2px solid #D3E5F1;
    border-radius: 50%;
}

.course_line .point {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin: 3px;
    background-color: #69B3E7;
}

.course_line .line {
    margin: 0 11px;
    width: 2px;
    height: 99px;
    background-color: #69B3E7;
}

.course_line div:nth-child(2) .line {
    height: 354px;

}

.course_line div:nth-child(3) .line {
    height: 59px;

}

.course_line div:nth-child(4) .line {
    height: 442px;

}

.course_line div:nth-child(5) .line {
    height: 0;
}

/*.course_line div:nth-child(6) .line {
    height: 280px;
}*/

.course .course_right>.list:nth-child(1) {
    margin-top: 125px;
}

.develop .two {
    margin: -24px 179px 0 180px;
}


.more {
    display: flex;
    font-size: 24px;
    font-weight: 600;
    color: #242933;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    padding: 30px 0 120px 0;
}

.more img {
    width: 32px;
    height: 32px;
    margin-left: 9px;

}

.map_box {
    width: 100%;
    min-width: 1440px;
    height: 1246px;
    position: relative;
    display: flex;
    justify-content: center;
    .map_img{
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        min-width: 1440px;
        display: flex;
        // justify-content: center;
        overflow: hidden;
    }
}

.map {
    width: 1440px;
    height: 1246px;
    color: #FAFBFC;
    margin: 0 auto;
    z-index: 99;
    position: absolute;
}

.map>.title {
    font-size: 40px;
    font-weight: 600;
    line-height: 64px;
    text-align: center;
    padding-top: 100px;
}

.map .text {
    display: flex;
    margin: 50px 280px 0;
}

.map .text .number {
    font-size: 100px;
    font-weight: normal;
    line-height: 158px;
}

.map .text .item:nth-child(1) {
    margin-right: 222px;
}

.map .text .item:nth-child(2) {
    margin-right: 195px;
}

.map .text .number sup {
    font-size: 36px;
    font-weight: normal;
    line-height: 47px;

}

.map .text .number sup>span {
    font-size: 24px;
    line-height: 32px;
}

.map .text .attr {
    text-align: center;
    font-size: 20px;
    font-weight: normal;
    color: #FFFFFF;
    line-height: 26px;
}

.pdcenter {
    position: relative;
    width: 1319px;
    height: 834px;
    margin: 0 60px 0 61px;
    background: url(https://cdns.iamberry.com/metapure/static/pcImg/us-map2.png) no-repeat center;
}

.pdcenter .title {
    font-size: 32px;
    font-weight: normal;
    color: #242933;
    line-height: 42px;
    text-align: center;
    padding-top: 146px;
}

.pdcenter .line {
    width: 2px;
    height: 64px;
    background-color: #00A3E0;
}

.pdcenter .place {
    font-size: 16px;
    font-weight: 600;
    color: #242933;
    line-height: 21px
}

.pdcenter .circle {
    width: 17px;
    height: 17px;
    border: 2px solid #00A3E0;
    border-radius: 50%;
}

.pdcenter .point {
    width: 11px;
    height: 11px;
    background-color: #00A3E0;
    border-radius: 50%;
    margin: 3px;
}

.dongguan {
    position: absolute;
    top: 329px;
    left: 849px;
}

.dongguan .place {
    margin-bottom: 8px;

}

.dongguan .line {
    margin-left: 55px;
}

.dongguan .circle {
    margin-left: 46px;

}

.anshan {
    position: absolute;
    top: 308px;
    left: 919px;
}

.anshan .place {
    margin-bottom: 8px;

}

.anshan .line {
    margin-left: 9px;
}

.anshan .circle {
    margin-left: 0;

}

.shenzhen {
    position: absolute;
    top: 435px;
    left: 918px;
}

.shenzhen .place {
    margin-top: 8px;

}

.shenzhen .line {
    margin-left: 9px;
}

.shenzhen .circle {
    margin-left: 0;
}
</style>