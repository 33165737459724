import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home'
import Product from '@/views/product/index'
import About from '@/views/About'
import Contact from '@/views/Contact'
import introduce from '@/views/product/introduce'
import mbHome from '@/views/mobile/home'
import mAbout from '@/views/mobile/mAbout'
import mContact from '@/views/mobile/mContact'
import mbintroduce from '@/views/mobile/introduce'

Vue.use(Router)

export default new Router({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/home',
      name: 'Home',
      component: Home
    },
    {
      path: '/home/product',
      name: 'product',
      component: Product
    },
    {
      path: '/About',
      name: 'About',
      component: About
    },
    {
      path: '/Contact',
      name: 'Contact',
      component: Contact
    },
    {
      path: '/home/introduce',
      name: 'introduce',
      component: introduce
    },
    // 移動端頁面
    {
      path: '/mobile/home',
      name: 'mbHome',
      component: mbHome
    },
    {
      path: '/mobile/mAbout',
      name: 'mAbout',
      component: mAbout
    },
    {
      path: '/mobile/mContact',
      name: 'mContact',
      component: mContact
    },
    {
      path: '/mobile/introduce',
      name: 'mbintroduce',
      component: mbintroduce
    }
]
})
