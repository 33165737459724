<template>
  <div class="navtop" >
    <img class="menu" :src="splitImageHttp(menuShow?'metapure/static/mobile/navTop/cancel.png':'metapure/static/mobile/navTop/menu.png')" alt="" @click="choseMenu"/>
    <img class="logo" :src="splitImageHttp('metapure/static/mobile/navTop/logo.png')" alt="" @click="transform('/mobile/home')"/>
    <!-- <img class="language" :src="splitImageHttp('metapure/static/mobile/navTop/ch.png')" alt=""/> -->
    <div class="navpro" v-show="menuShow">
      <div class="menuLi" v-for="(item, index) in productList" :key="index">
        <div class="menuLiTitle">
          <span>{{ item.name }}</span>
          <div class="imgbox" @click="chosePro(index)">
            <img :src="splitImageHttp(productIndex === index ? 'metapure/static/mobile/navTop/iconOn.png' : 'metapure/static/mobile/navTop/iconOff.png')" alt=""/>
          </div>
        </div>
        <div class="menuPro" v-if="productIndex === index">
          <div class="prodetail" v-for="(item2, index2) in item.children" :key="index2" @click="productDetail(item2.id)">
            <div class="proImg">
              <img :src="splitImageHttp(item2.imgsrc)" alt="" />
            </div>
            <span class="proName">{{ item2.name }}</span>
            <div class="projs" v-if="item2.color">
              <p :style="{ background: item2.color }"></p>
              <span>{{ item2.colorName }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="menuLi">
        <div class="menuLiTitle">
          <span>关于我们</span>
          <div class="imgbox" @click="chosePro(99)">
            <img :class="{roate: AboutShow}" :src="splitImageHttp('metapure/static/mobile/navTop/right.png')" alt=""/>
          </div>
        </div>
        <p v-show="AboutShow" @click="transform(item.path)" class="pronavlink" v-for="(item, index) in about" :key="index">{{ item.name }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import jsonData from "@/commom/mock.js";
export default {
  data() {
    return {
      AboutShow: false,
      menuShow: false,
      productIndex: null,
      productList: [],
      about:[{name: '公司简介', path: '/mobile/mAbout'},{name: '联系我们', path: '/mobile/mContact'}]
    };
  },
  created() {
    this.productList = jsonData.mobileProduct;
    this.$eventBus.$on('choseproduct', (e)=>{
        this.menuShow = true
        document.body.style.overflowY='hidden';
        this.productIndex = e
    });
    //人气产品跳转至导航栏
    this.$eventBus.$on('seeMore', (e)=>{
        this.menuShow = true
        document.body.style.overflowY='hidden';
        this.productIndex = 0
    });
  },
  methods: {
    productDetail(id){
      document.body.style.overflowY='scroll';
      if(this.$route.path==='/mobile/introduce'){
        console.log(window.location)
        let url = window.location.origin + `/mobile/introduce?id=${id}`
        window.location.href = url
        return
      }
      this.$router.push({path: '/mobile/introduce',query:{id: id}})
    },
    transform(path){
      document.body.style.overflowY='scroll';
      if(this.$route.path===path) return
      this.$router.push({path: path})
    },
    choseMenu(){
        if(!this.menuShow){
          document.body.style.overflowY='hidden';
        }else{
          document.body.style.overflowY='scroll';
        }
        this.menuShow = !this.menuShow
    },
    chosePro(key){
        if(key===this.productIndex){
            this.productIndex = null
            return
        }
        if(key===99){
          this.AboutShow = !this.AboutShow
          return
        }
        this.productIndex = key
        this.AboutShow = false
    }
  }
};
</script>
<style lang="less" scoped>
.roate{
  transform: rotate(90deg);
}
.navtop {
  width: 100%;
  height: 96rem;
  display: flex;
  padding: 32rem;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  .navpro {
    position: absolute;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    box-sizing: border-box;
    padding: 2rem 32rem 180rem 32rem;
    left: 0;
    top: 96rem;
    background: #ffffff;
    z-index: 999;
    .prodetail {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 32rem;
      .proImg {
        width: 331rem;
        height: 364rem;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            width: 331rem;
            height: 364rem;
        }
      }
      .proName {
        color: #242933;
        font-size: 24rem;
        line-height: 32rem;
        margin-top: 20rem;
      }
      .projs {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #242933;
        font-size: 20rem;
        line-height: 26rem;
        margin-top: 12rem;
        p {
          width: 16rem;
          height: 16rem;
          border-radius: 50%;
          margin-right: 12rem;
        }
      }
    }
    .menuLi {
      border-bottom: 2rem solid #e6e6e6;
      width: 686rem;
      min-height: 103rem;
      box-sizing: border-box;
      padding-right: 16rem;
      .pronavlink{
        padding-bottom: 40rem;
        // width: 100%;
        // height: 60rem;
        color: #242933;
        font-size: 24rem;
        // line-height: 60rem;
      }
      .menuPro {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      .menuLiTitle {
        color: #242933;
        font-size: 28rem;
        line-height: 37rem;
        width: 100%;
        height: 101rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .imgbox{
          width: 80rem;
          height: 80rem;
          display: flex;
          align-items: center;
          flex-direction:row-reverse
        }
        // font-weight: bold;
        img {
          width: 20rem;
          height: 20rem;
        }
      }
    }
  }
  .menu {
    width: 36rem;
    height: 36rem;
    margin-right: 183rem;
  }
  .logo {
    width: 247rem;
    height: 35rem;
    margin-right: 170rem;
  }
  .language {
    width: 36rem;
    height: 36rem;
  }
}
</style>