<template>
  <div class="mBanner">
    <swiper ref="mySwiper" class="swiper" :options="options">
      <swiper-slide class="swiperct" v-for="(item, index) in pouductList" :key="index">
        <img :src="splitImageHttp(item.img[item.key].src)" alt="" :data-path="item.img[item.key].id" @click="productDetail(item.img[item.key].id) ">
        <p class="title">{{ item.name }}</p>
        <div class="chosedos">
          <div class="waic" v-for="(item2, index2) in item.img" :key="index2" :class="{ showborder: index2 === item.key }"
            @click="changes(index, index2)">
            <p class="neic" :style="{ 'background': item2.color }"></p>
          </div>
          <span>{{ item.img[item.key].name }}</span>
        </div>
      </swiper-slide>
      <!-- <div class="swiper-pagination" slot="pagination"></div> -->
    </swiper>
  </div>
</template>
<script>
let vm = null
export default {
  name: 'mbautobanner',
  data() {
    return {
      pouductList: [{ name: '富氢系列-Modern 5', key: 0, img: [{ name: '雾海蓝', src: 'metapure/static/mobile/home/popular_product/hot_M5.png', color: 'rgba(92, 118, 143, 1)', id: 8 }] },
      { name: 'C时尚系列-Convenient 1', key: 0, img: [{ name: '云水蓝', src: 'metapure/static/mobile/home/popular_product/hot_c1.png', color: 'rgba(225, 231, 235, 1)', id: 6 }] },
      {
        name: 'H矿化系列-岩泉 Healthy 7', key: 0, img: [{ name: '深空灰', src: 'metapure/static/mobile/home/popular_product/hot_h7g.png', color: 'rgba(113, 113, 113, 1)', id: 2 },
        { name: '净息白', src: 'metapure/static/mobile/home/popular_product/hot_h7w.png', color: 'rgba(216, 216, 216, 1)', id: 1 }]
      },
      {
        name: 'R复古系列-Retro 3', key: 0, img: [{ name: '奶油白', src: 'metapure/static/mobile/home/popular_product/hot_r3w.png', color: 'rgba(247, 240, 230, 1)' ,id:4},
        { name: '玛瑙蓝', src: 'metapure/static/mobile/home/popular_product/hot_r3b.png', color: 'rgba(200, 219, 224, 1)',id:3 },
        { name: '陨石灰', src: 'metapure/static/mobile/home/popular_product/hot_r3g.png', color: 'rgba(124, 124, 125, 1)' ,id:5}]
      },
      ],
      //配置项例子
      options: {
        slidesPerView: "auto",
				autoplay: false, //设置为true启动自动切换，并使用默认的切换设置。
        loop: false,
        freeMode: false,
        click: function(e){
            if(!e.target.dataset.path) return
            vm.productDetail(e.target.dataset.path)
        },
        navigation: {
          //下一张
          nextEl: '.btn-right',//下一张标签类名可以自定义
          //上一张
          prevEl: '.btn-left'//上一张标签类名可以自定义
        },
      },
    };
  },
  created(){
    vm = this
  },
  methods: {
    changes(index, index2) {
      this.pouductList[index].key = index2
    },
    //人气产品跳转详情页
    productDetail(id) { this.$router.push({ path: '/mobile/introduce', query: { id: id} }) },

  }
};
</script>
<style lang="less" scoped>
.mbanner {
  width: 100%;
  min-height: 688rem;
  overflow: hidden;
}

.swiper {
  width: 686rem;
  height: 688rem;
  overflow: visible !important;

  // margin: 0 left;
  .swiperct {
    width: 534rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      margin-top: 40rem;
      margin-bottom: 28rem;
      color: #FFFFFF;
      font-size: 36rem;
      line-height: 56rem;
    }

    .chosedos {
      display: flex;
      align-items: center;
      justify-content: center;

      .showborder {
        border: 1rem solid #CBCBCB;
      }

      .neic {
        width: 48rem;
        height: 48rem;
        border-radius: 50%;
      }

      .waic {
        width: 48rem;
        height: 48rem;
        padding: 6rem;
        margin-right: 40rem;
        border-radius: 50%;
        z-index: 9999;
      }

      span {
        color: #CBCBCB;
        font-size: 28rem;
        line-height: 37rem;
      }
    }

    img {
      width: 500rem;
      height: 500rem;
    }
  }
}
</style>
