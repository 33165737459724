import axios from 'axios'
import qs from 'qs'
import { VueAxios } from './axios'
import ElementUI from 'element-ui'
// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: '/api',
  timeout: 6000 // 请求超时时间
})
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'

const errorMsg = {
  14000001: '找不到资源路径404',
  14000002: '未登录',
  14000003: 'TOKEN已过期',
  14000004: '权限不足',
  '-1': '服务器未响应',
  500: '系统异常，请联系管理员！'
}

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.code) {
    const data = error
    const code = error.code
    // 14000001  找不到资源路径404
    // 14000002  未登录
    // 14000003  TOKEN已过期
    // 14000004   权限不足
    // -1 服务器未响应
    if (code === '14000003' || code === '14000002') {
      ElementUI.Message({
        message: errorMsg[code],
        type: 'error'
      })
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    } else if (code === '-1' || code === '500' || code === '14000001' || code === '14000002') {
      ElementUI.Message({
        message: errorMsg[code],
        type: 'error'
      })
    } else {
      ElementUI.Message({
        message: data.msg,
        type: 'error'
      })
    }
  }
  // return Promise.reject(error)
  return error
}
// response interceptor
request.interceptors.response.use((response) => {
  return new Promise((resolve) => {
    const res = response.data
    if (res.code !== 10000000) {
      errorHandler(response.data)
      res.success = false
    } else {
      res.success = true
      resolve(res)
    }
  })
}, errorHandler)
const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, request)
  }
}
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
  return new Promise((resolve, reject) => {
    request.get(url, {
        params: params
      })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params, config) {
  return new Promise((resolve, reject) => {
    let parameter = null
    if (config && (config.headers['Content-Type'] === 'application/json;charset=UTF-8' || config.headers['Content-Type'] === 'multipart/form-data')){
      parameter = params
    } else {
      parameter = qs.stringify(params)
    }
    request.post(url, parameter, config)
      .then(res => {
        resolve(res)
        console.log('请求')
      })
      .catch(err => {
        console.log(err, 'error')
        reject(err)
      })
  })
}

export default request

export {
  installer as VueAxios,
  request as axios
}