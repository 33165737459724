<template>
    <div class="mContact">
        <navTop></navTop>
        <div class="map">
            <div id="bai-du-map"></div>
        </div>
        <div class="contact">
            <div class="title">商务合作联系方式</div>
            <div class="titles">深圳爱贝源科技有限公司</div>
            <div class="way">
                <div class="title1">
                    <img :src="splitImageHttp('metapure/static/mobile/contact/tel.png')" alt="">
                    商务合作联系方式
                </div>
                <div class="content">
                    <div style="margin-top: 0;">地址：深圳市龙华区龙华街道景龙社区龙华大道3639号环智中心C座15层1505、1506B</div>
                    <div>邮编：518000</div>
                    <div>邮箱：aiberle@iamberry.com</div>
                    <div>电话： <span> <a href="tel:18925292910"> 189 2529 2910</a></span></div>
                </div>
                <div class="title1">
                    <img :src="splitImageHttp('metapure/static/mobile/contact/tel2.png')" alt="">
                    客户服务联系方式
                </div>
                <div class="content">
                    <div>电话：<span><a href="tel:4008006692">400 800 6692</a></span></div>
                </div>
                <div class="title1">
                    <img :src="splitImageHttp('metapure/static/mobile/contact/wx.png')" alt="">
                    在线客服联系方式
                </div>
                <div class="content">
                    <div @click="getCode(kefu,codeName,centerDialogVisible)"><span>爱贝源公众号 - 在线客服</span></div>
                </div>
            </div>
        </div>
        <!-- 二维码 -->
        <el-dialog :visible.sync="centerDialogVisible" width="75%" :show-close="false" :modal-append-to-body="false" :close-on-click-modal="false" :center="true">
            <img class="code" :src="kefu" alt="">
            <div class="title2">爱贝源公众号 - 在线客服</div>
            <div class="text"><span @click="downLoadImg(kefu, codeName)">保存二维码</span> &nbsp; 后可在微信扫码关注</div>
            <img class="postionClose" @click="()=>{this.centerDialogVisible= false}"  :src="splitImageHttp('metapure/static/mobile/contact/close.png')" alt="">
        </el-dialog>
        <!-- <div class="QR_code" v-show="code">
            <div class="box">
                <img :src="kefu" alt="">
                
                <div class="close" @click="closeCode(code, kefu, '客服二维码.png')"><img
                        :src="splitImageHttp('metapure/static/mobile/contact/close.png')" alt=""></div>
            </div>
        </div> -->
        <navBt></navBt>
    </div>
</template>
<script>
import navTop from "@/components/mobile/mobileTop.vue";
import navBt from "@/components/mobile/mobileBt.vue";
import AMapLoader from "@amap/amap-jsapi-loader";
import kefu from '@/assets/QR_code.png';

window._AMapSecurityConfig = {
    securityJsCode: "516195c91c09ba390aab8f359fe04b91",
};
export default {
    name: 'mContact',
    components: {
        navTop,
        navBt,
    },
    data() {
        return {
            centerDialogVisible: false,
            map: null,
            mouseTool: null,
            overlays: [],
            auto: null,
            placeSearch: null,
            // 二维码
            code: false,
            kefu: kefu,
            codeName: '在线客服二维码.png',

        };
    },
    mounted() {
        this.initMap()
    },
    methods: {
        initMap() {
            AMapLoader.load({
                key: "0391591b9783173f28331141fa094115",
                version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
            })
                .then((AMap) => {
                    // 初始化地图
                    this.map = new AMap.Map("bai-du-map", {
                        viewMode: "2D", //  是否为3D地图模式
                        zoom: 200, // 初始化地图级别
                        center: [114.030343, 22.643627], //中心点坐标  郑州
                        resizeEnable: true,
                    });
                    this.setMapMarker();
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        // 增加点标记
        setMapMarker() {
            // 创建 AMap.Icon 实例
            let icon = new AMap.Icon({
                size: new AMap.Size(46, 46), // 图标尺寸
                image: 'https://cdns.iamberry.com/metapure/static/images/postion2.png', // Icon的图像
                imageSize: new AMap.Size(46, 46), // 根据所设置的大小拉伸或压缩图片
                imageOffset: new AMap.Pixel(0, 0)  // 图像相对展示区域的偏移量，适于雪碧图等
            });
            let makerList = []
            let marker = new AMap.Marker({
                map: this.map,
                zIndex: 9999999,
                icon: icon, // 添加 Icon 实例
                offset: new AMap.Pixel(-13, -30), //icon中心点的偏移量
                position: [114.030343, 22.643627] // 经纬度对象new AMap.LngLat(x, y)，也可以是经纬度构成的一维数组[116.39, 39.9]
            });
            makerList.push(marker)
            this.map.add(makerList)
            // 自动适应显示想显示的范围区域
            this.map.setFitView();
        },

        // 二维码
        getCode(url,name,centerDialogVisible) {
            this.kefu=url;
            this.codeName=name;
            this.centerDialogVisible = !this.centerDialogVisible;
        },
        closeCode() {
            this.centerDialogVisible = false;
        },
        //下载二维码
        getUrlBase64(url) {
            return new Promise(resolve => {
                let canvas = document.createElement('canvas')
                let ctx = canvas.getContext('2d')
                let img = new Image()
                img.crossOrigin = 'anonymous' //允许跨域
                img.src = url
                img.onload = function () {
                    canvas.height = 678
                    canvas.width = 678
                    ctx.drawImage(img, 0, 0, 678, 678)
                    let dataURL = canvas.toDataURL('image/png')
                    canvas = null
                    resolve(dataURL)
                }
            })
        },
        downLoadImg(url, filename) {
            this.getUrlBase64(url).then(base64 => {
                this.url = url
                let link = document.createElement('a')
                link.href = base64
                link.download = filename
                link.click()
            })
        },

    }
}
</script>
<style lang="less" scoped>
.mContact {
    position: relative;
    width: 100%;
    height: 100vh;
    /*height: 100rem*/
    ;
    margin: 0 auto;

    .contact {
        position: relative;
        width: 100%;
        min-height: 939rem;
        background: url('https://cdns.iamberry.com/metapure/static/mobile/contact/mContact_bg.png') no-repeat center;
        background-size: 750rem 939rem;
        box-sizing: border-box;
        padding: 80rem 0 90rem;

        .title {
            font-size: 36rem;
            font-weight: 600;
            color: #242933;
            line-height: 56rem;
            text-align: center;
        }

        .titles {
            font-size: 24rem;
            font-weight: normal;
            color: #5B5F68;
            line-height: 32rem;
            margin: 16rem 0 29rem;
            text-align: center;
        }

        .way {
            width: 686rem;
            min-height: 636rem;
            background: rgba(255, 255, 255, 0.8);
            box-shadow: 0 10rem 26rem 0 rgba(0, 0, 0, 0.02);
            border-radius: 24rem;
            backdrop-filter: blur(50rem);
            margin: 0 auto;
            box-sizing: border-box;
            padding: 56rem 82rem 56rem 48rem;

            .title1 {
                display: flex;
                font-size: 28rem;
                font-weight: 600;
                color: #242933;
                line-height: 44rem;
                align-items: center;
                margin-top: 48rem;

                img {
                    width: 40rem;
                    height: 30rem;
                    margin-right: 24rem;
                }

            }

            .content {

                font-size: 24rem;
                font-weight: normal;
                color: #5B5F68;
                line-height: 32rem;
                margin-left: 64rem;

                div {
                    margin-top: 16rem;

                    span {
                        color: #69B3E7 !important;

                        a {
                            color: #69B3E7 !important;
                        }
                    }
                }
            }
        }
    }
}

.way .title1:first-child {
    margin-top: 0 !important;
    margin-bottom: 10rem !important;
}

.content>div:last-child {
    margin-top: 10rem !important;
}
.postionClose{
  position: absolute;
  width: 68rem;
  height: 68rem;
  bottom: -120rem;
  left: 43%;
}
.title2 {
  font-size: 32rem;
  font-weight: 600;
  color: #333333;
  line-height: 21rem;
  text-align: center;
  margin: 59rem 0 32rem 0;
}
.text {
  font-size: 24rem;
  font-weight: normal;
  color: #5B5F68;
  line-height: 21rem;
  text-align: center;
  padding-bottom: 40rem;
span {
  color: #69B3E7;
}
}
.code{
  width: 400rem;
  height: 400rem;
  margin: 32rem auto;
}

/deep/ .el-dialog--center{
    border-radius: 20rem;
}
/deep/ .el-dialog__header{
    padding: 0 !important;
}
/deep/ .el-dialog{
  z-index: 9999;
  margin-top: 20vh !important;
}
/deep/ .el-dialog--center .el-dialog__body{
    padding: 25rem 25rem 0 30rem;
}
/deep/ .v-modal{
  opacity: 0.7 !important;
}
/*二维码*/
.QR_code {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.7;
    z-index: 999;
    //box-sizing: border-box;
    //padding: 152rem 115rem 153rem;
    padding-bottom: 1600rem;

    .box {
        position: relative;
        width: 520rem;
        height: 643rem;
        background: #FFFFFF;
        border-radius: 24rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        padding-top: 82rem;
        margin: 1139rem 115rem 153rem;

        img {
            width: 339rem;
            height: 339rem;
        }

        

        .close {
            position: absolute;
            top: 690rem;
            left: 226rem;
            width: 68rem;
            height: 68rem;
            cursor: pointer;

            img {
                width: 68rem;
                height: 68rem;
            }
        }
    }
}

/*百度地图*/
#bai-du-map {
    overflow: hidden;
    width: 100%;
    height: 100%;
    margin: 0;
    font-family: "微软雅黑";
}

/* 隐藏高德logo  */
.amap-logo {
    display: none !important;
}

/* 隐藏高德版权  */
.amap-copyright {
    display: none !important;
}

.map {
    width: 100%;
    height: 987rem;
    box-sizing: border-box;
}
</style>